import React from 'react';
import './repeatOptions.css';

export const RepeatOptions = () => {
    return (
        <div className='repeatOptions'>
            
            <h2 id="repeatHeader">Repeat Options</h2>

            <div id="repeatEndDateDiv">
                <label htmlFor="repeatEndDate">End of Repeating Period: </label>
                    <input type="date" id="repeatEndDate" 
                    name="repeatEndDate"/>
            </div>
            <div className="formSep"></div>
            <div id="repeatFreqDiv">
                <label htmlFor="repeatFreqSelect">Repeat Frequency: </label>
                    <select id="repeatFreqSelect">
                        <option>Weekly</option>
                        <option>Every Other Week</option>
                    </select>
            </div>
            <div className="formSep"></div>
            <div id="repeatDaysDiv">
                <label htmlFor="repeatDaysCheckboxes">
                    <div id="repeatDaysCheckboxes">
                        <div className="repeatDayAndCheckboxDiv">
                            <label for="mondayRepeat">Monday</label>
                            <input type="checkbox" id="mondayRepeat" name="Monday" value="Monday" /> 
                        </div>
                    
                        <div className="repeatDayAndCheckboxDiv">
                            <label for="tuesdayRepeat">Tuesday</label>
                            <input type="checkbox" id="tuesdayRepeat" name="Tuesday" value="Tuesday" /> 
                        </div>

                        <div className="repeatDayAndCheckboxDiv">
                            <label for="wednesdayRepeat">Wednesday</label>
                            <input type="checkbox" id="wednesdayRepeat" name="Wednesday" value="Wednesday" /> 
                        </div>

                        <div className="repeatDayAndCheckboxDiv">
                            <label for="thursdayRepeat">Thursday</label>
                            <input type="checkbox" id="thursdayRepeat" name="Thursday" value="Thursday" /> 
                        </div>

                        <div className="repeatDayAndCheckboxDiv">
                            <label for="fridayRepeat">Friday</label>
                            <input type="checkbox" id="fridayRepeat" name="Friday" value="Friday" /> 
                        </div>

                        <div className="repeatDayAndCheckboxDiv">
                            <label for="saturdayRepeat">Saturday</label>
                            <input type="checkbox" id="saturdayRepeat" name="Saturday" value="Saturday" /> 
                        </div>

                        <div className="repeatDayAndCheckboxDiv">
                            <label for="sundayRepeat">Sunday</label>
                            <input type="checkbox" id="sundayRepeat" name="Sunday" value="Sunday" />
                        </div>
                    
                    
                    
                    
                    
                    
                   

                    </div>
                </label>
            </div>
            
        </div>
    )
}