import './addShiftBox.css';
import { Button } from './Button';
import React, { useState, useEffect } from 'react'
import { RepeatOptions } from './RepeatOptions';
import { CaretakerSelect } from './CaretakerSelect';

export const AddShiftBox = (props) => {

    useEffect(() => {
        getClients();
        getCaretakers();
    }, [props.caretakerList, props.clientList])
    
    const gatherFields = () => {
        let chosenClient = document.getElementById('clientSelect').value;
        let chosenCaretaker = document.getElementById('caretakerSelect').value;
        
        let startDate = document.getElementById('shiftStartDate').value;
        let startTime = document.getElementById('shiftStartTime').value;
        let endDate = document.getElementById("shiftEndDate").value;
        let endTime = document.getElementById('shiftEndTime').value;
        let shiftComments = document.getElementById('shiftComments').value;
        let shiftMileage = document.getElementById("mileage").value;
        let repeatShift = document.getElementById('repeatSelect').value;
        let overnightShift = startDate !== endDate;
        let startDatetime = startDate + "T" + startTime;
        let endDatetime = endDate + "T" + endTime;
        let currDate = new Date(startDatetime);
        let startTimeSecs = currDate.getTime();
        let caretakerFromList = props.caretakerList.filter(caretaker => caretaker.name === chosenCaretaker)[0];

        let isRepeatingShift = props.renderRepeatOptions;
        let isFlatFee = props.renderFlatFeeOptions;


        let shiftsToAdd = [];

        // If shift mileage is not set, set to 0
        if (shiftMileage == "") {
            shiftMileage = 0;
        }

        if (startDate == endDate && endTime < startTime) {
            console.log("times invalid")
            document.getElementById("endTimeErrorDiv").style.height = "5vh";
            document.getElementById("endTimeErrorDiv").style.color = "#ff0000";
            document.getElementById("endTimeErrorDiv").innerHTML = "Error: End time is before start time";
            return;

        }

        // Get the caretakers shift calendar color
        let caretakerColor = caretakerFromList.color;

        // If the shift is not repeating
        if (!isRepeatingShift) {

            // Get next day information
            let nextDayDateTime = new Date(endDatetime); 
            const nextDayDateYear = nextDayDateTime.getFullYear();
            let nextDayDateMonth = nextDayDateTime.getMonth() + 1;
            let nextDayDateDay = nextDayDateTime.getDate();

            // pad next day infomation to fit calendar format
            if (nextDayDateMonth.toString().length < 2) {
                nextDayDateMonth = "0" + nextDayDateMonth;
            }
            if (nextDayDateDay.toString().length < 2) {
                nextDayDateDay = "0" + nextDayDateDay;
            }

            // next day timestamp
            const nextDayDateTimestamp = `${nextDayDateYear}-${nextDayDateMonth}-${nextDayDateDay}T${endTime}`;



            // Shift is not repeating, and is a flat fee
            if (isFlatFee) {

                // get flat fee information for client and caretaker
                let flatFeeCaretaker = document.getElementById("flatFeeInputCaretaker").value;
                let flatFeeClient = document.getElementById("flatFeeInputClient").value;

                let shiftObj = {
                    chosenClient: chosenClient, 
                    chosenCaretaker: chosenCaretaker, 
                    startTimeSecs: startTimeSecs, 
                    startDatetime: startDatetime, 
                    endDatetime: nextDayDateTimestamp, 
                    shiftComments: shiftComments, 
                    caretakerColor: caretakerColor, 
                    shiftRepeat: false, 
                    shiftRepeatID: null, 
                    isFlatFeeShift: true, 
                    flatFeeCaretaker: flatFeeCaretaker, 
                    shiftMileage: shiftMileage, 
                    flatFeeClient: flatFeeClient
                }
                shiftsToAdd.push(shiftObj);


                // Add non repeating, with flat fee shift
                //props.addShift(chosenClient, chosenCaretaker, startTimeSecs, startDatetime, nextDayDateTimestamp, shiftComments, caretakerColor, false, null, true, flatFeeCaretaker, shiftMileage, flatFeeClient);
            } else {

                let shiftObj = {
                    chosenClient: chosenClient, 
                    chosenCaretaker: chosenCaretaker, 
                    startTimeSecs: startTimeSecs, 
                    startDatetime: startDatetime, 
                    endDatetime: nextDayDateTimestamp, 
                    shiftComments: shiftComments, 
                    caretakerColor: caretakerColor, 
                    shiftRepeat: false, 
                    shiftRepeatID: null, 
                    isFlatFeeShift: false, 
                    flatFeeCaretaker: null, 
                    shiftMileage: shiftMileage, 
                    flatFeeClient: null
                }
                shiftsToAdd.push(shiftObj);

                // Add non repeating, without flat fee
               // props.addShift(chosenClient, chosenCaretaker, startTimeSecs, startDatetime, nextDayDateTimestamp, shiftComments, caretakerColor, false, null, false, null, shiftMileage, null);
            }
            
        // Shift is repeating, but not an overnight shift
        } else if (isRepeatingShift && overnightShift !== "Yes") {

            

            // Dont believe this is necessary, remove soon.
            let dateInADay = new Date(startDatetime);

            // Get daily repeat checkbox values
            let mondayRepeat = document.getElementById("mondayRepeat").checked;
            let tuesdayRepeat = document.getElementById("tuesdayRepeat").checked;
            let wednesdayRepeat = document.getElementById("wednesdayRepeat").checked;
            let thursdayRepeat = document.getElementById("thursdayRepeat").checked;
            let fridayRepeat = document.getElementById("fridayRepeat").checked;
            let saturdayRepeat = document.getElementById("saturdayRepeat").checked;
            let sundayRepeat = document.getElementById("sundayRepeat").checked;

            // Array to hold checked/unchecked values for weekly repeat
            let daysOfTheWeekRepeatArray = [];

            // Add weekly repeat values to the array
            daysOfTheWeekRepeatArray.push(sundayRepeat);
            daysOfTheWeekRepeatArray.push(mondayRepeat);
            daysOfTheWeekRepeatArray.push(tuesdayRepeat);
            daysOfTheWeekRepeatArray.push(wednesdayRepeat);
            daysOfTheWeekRepeatArray.push(thursdayRepeat);
            daysOfTheWeekRepeatArray.push(fridayRepeat);
            daysOfTheWeekRepeatArray.push(saturdayRepeat);

            let shiftCount = 0;
            
            // Get the end cutoff date for repeating shift
            let repeatEndDate = document.getElementById("repeatEndDate").value;

            // Create a date object for end of repeat date
            let repeatEndDateObject = new Date(repeatEndDate);

            // Increase day of end date to make date inclusive
            repeatEndDateObject.setDate(repeatEndDateObject.getDate() + 1);
            
            // endTime for dateInADay
            let dateInADayEndTime = new Date(endDatetime);
            
            // Add an ID for repeating shift comprised of current time, client and caretaker
            const shiftRepeatID = `${currDate.getTime()}--${chosenClient}:${chosenCaretaker}`;

            // Get repeat frequency value, i.e. weekly, every other week
            let repeatFrequency = document.getElementById("repeatFreqSelect").value;

            // Shift is repeating, is not overnight, and has a flat fee
            if (isFlatFee) {
                
                // Get the amount the caretaker will be paid
                let flatFeeCaretaker = document.getElementById("flatFeeInputCaretaker").value;

                // Get the amount the client will pay the company
                let flatFeeClient = document.getElementById("flatFeeInputClient").value;

                let shiftObj = {
                    chosenClient: chosenClient, 
                    chosenCaretaker: chosenCaretaker, 
                    startTimeSecs: startTimeSecs, 
                    startDatetime: startDatetime, 
                    endDatetime: endDatetime, 
                    shiftComments: shiftComments, 
                    caretakerColor: caretakerColor, 
                    shiftRepeat: true, 
                    shiftRepeatID: shiftRepeatID, 
                    isFlatFeeShift: true, 
                    flatFeeCaretaker: flatFeeCaretaker, 
                    shiftMileage: shiftMileage, 
                    flatFeeClient: flatFeeClient
                }
                shiftsToAdd.push(shiftObj);

                // Add shift with flat fee
               // props.addShift(chosenClient, chosenCaretaker, startTimeSecs, startDatetime, endDatetime, shiftComments, caretakerColor, true, shiftRepeatID, true, flatFeeCaretaker, shiftMileage, flatFeeClient);
            } else {
                // Add shift without flat fee

                let shiftObj = {
                    chosenClient: chosenClient, 
                    chosenCaretaker: chosenCaretaker, 
                    startTimeSecs: startTimeSecs, 
                    startDatetime: startDatetime, 
                    endDatetime: endDatetime, 
                    shiftComments: shiftComments, 
                    caretakerColor: caretakerColor, 
                    shiftRepeat: true, 
                    shiftRepeatID: shiftRepeatID, 
                    isFlatFeeShift: false, 
                    flatFeeCaretaker: null, 
                    shiftMileage: shiftMileage, 
                    flatFeeClient: null
                }
                shiftsToAdd.push(shiftObj);
               // props.addShift(chosenClient, chosenCaretaker, startTimeSecs, startDatetime, endDatetime, shiftComments, caretakerColor, true, shiftRepeatID, false, null, shiftMileage, null);
            }
            
            // while date < end date cutoff
            while (dateInADay.getTime() < repeatEndDateObject.getTime()) {

                shiftCount++;

                // Increase the date by 8 to skip a week
                if (repeatFrequency == "Every Other Week" && shiftCount % 7 == 0) {
                    dateInADay.setDate(dateInADay.getDate() + 8);
                    dateInADayEndTime.setDate(dateInADayEndTime.getDate() + 8);
                } else { // Increase the date by 1 to do each week
                    dateInADay.setDate(dateInADay.getDate() + 1);
                    dateInADayEndTime.setDate(dateInADayEndTime.getDate() + 1);
                }

                // If the current day of the week is checked, add to calendar
                if (daysOfTheWeekRepeatArray[dateInADay.getDay()] == true) {


                // Get the current date format, pad if necessary
                    startTimeSecs = dateInADay.getTime();
                let dateInADayYear = dateInADay.getFullYear();
                let dateInADayMonth = dateInADay.getMonth() + 1;
                let dateInADayDate = dateInADay.getDate();
                let dateInADayHours = dateInADay.getHours();
                let dateInADayMins = dateInADay.getMinutes();
                if (dateInADayDate.toString().length < 2) {
                    dateInADayDate = "0" + dateInADayDate;
                }
                if (dateInADayMonth.toString().length < 2) {
                    dateInADayMonth = "0" + dateInADayMonth;
                }
                if (dateInADayHours.toString().length < 2) {
                    dateInADayHours = "0" + dateInADayHours;
                }
                if (dateInADayMins.toString().length < 2) {
                    dateInADayMins = "0" + dateInADayMins;
                }
                
                // Get finished date format
                let dateInADayFormat = `${dateInADayYear}-${dateInADayMonth}-${dateInADayDate}T${dateInADayHours}:${dateInADayMins}`;
                
                // Get end date format and pad if necessary
                let dateInADayEndTimeYear = dateInADayEndTime.getFullYear();
                let dateInADayEndTimeMonth = dateInADayEndTime.getMonth() + 1;
                let dateInADayEndTimeDate = dateInADayEndTime.getDate();
                let dateInADayEndTimeHours = dateInADayEndTime.getHours();
                let dateInADayEndTimeMins = dateInADayEndTime.getMinutes();

                if (dateInADayEndTimeDate.toString().length < 2) {
                    dateInADayEndTimeDate = "0" + dateInADayEndTimeDate;
                }
                if (dateInADayEndTimeMonth.toString().length < 2) {
                    dateInADayEndTimeMonth = "0" + dateInADayEndTimeMonth;
                }
                if (dateInADayEndTimeHours.toString().length < 2) {
                    dateInADayEndTimeHours = "0" + dateInADayEndTimeHours;
                }
                if (dateInADayEndTimeMins.toString().length < 2) {
                    dateInADayEndTimeMins = "0" + dateInADayEndTimeMins;
                }

                // Get finished end date format
                const dateInADayEndTimeFormat = `${dateInADayEndTimeYear}-${dateInADayEndTimeMonth}-${dateInADayEndTimeDate}T${dateInADayEndTimeHours}:${dateInADayEndTimeMins}`;
                
                // Repeating shift and flat fee
                if (isFlatFee) {
                    let flatFeeCaretaker = document.getElementById("flatFeeInputCaretaker").value;
                    let flatFeeClient = document.getElementById("flatFeeInputClient").value;

                    let shiftObj = {
                        chosenClient: chosenClient, 
                        chosenCaretaker: chosenCaretaker, 
                        startTimeSecs: startTimeSecs, 
                        startDatetime: dateInADayFormat, 
                        endDatetime: dateInADayEndTimeFormat, 
                        shiftComments: shiftComments, 
                        caretakerColor: caretakerColor, 
                        shiftRepeat: true, 
                        shiftRepeatID: shiftRepeatID, 
                        isFlatFeeShift: true, 
                        flatFeeCaretaker: flatFeeCaretaker, 
                        shiftMileage: shiftMileage, 
                        flatFeeClient: flatFeeClient
                    }

                    shiftsToAdd.push(shiftObj);

                    // props.addShift(chosenClient, chosenCaretaker, startTimeSecs, dateInADayFormat, dateInADayEndTimeFormat, shiftComments, caretakerColor, true, shiftRepeatID, true, flatFeeCaretaker, shiftMileage, flatFeeClient);
                } else { // Repeating shift and not a flat fee

                    let shiftObj = {
                        chosenClient: chosenClient, 
                        chosenCaretaker: chosenCaretaker, 
                        startTimeSecs: startTimeSecs, 
                        startDatetime: dateInADayFormat, 
                        endDatetime: dateInADayEndTimeFormat, 
                        shiftComments: shiftComments, 
                        caretakerColor: caretakerColor, 
                        shiftRepeat: true, 
                        shiftRepeatID: shiftRepeatID, 
                        isFlatFeeShift: false, 
                        flatFeeCaretaker: null, 
                        shiftMileage: shiftMileage, 
                        flatFeeClient: null
                    }
                    
                    shiftsToAdd.push(shiftObj);

                    //props.addShift(chosenClient, chosenCaretaker, startTimeSecs, dateInADayFormat, dateInADayEndTimeFormat, shiftComments, caretakerColor, true, shiftRepeatID, false, null, shiftMileage, null);
               
                }

                }
                
            }

            // Reset flat fee and repeat options divs to be hidden
            document.getElementById("addShiftBoxDiv").style.height = "100vh";
            document.getElementById("repeatShiftOptionsDiv").style.height = "0vh";
            document.getElementById("flatFeeOptionsDiv").style.height = "0vh";
            props.setRenderRepeatOptions(false);
            props.setRenderFlatFeeOptions(false);
            
            
        // Repeating overnight shift    
        } else if (isRepeatingShift && overnightShift === "Yes") {

            // create nextDayDateTime Date object
            let nextDayDateTime = new Date(endDatetime);

            // Build date format
            const nextDayDateYear = nextDayDateTime.getFullYear();
            let nextDayDateMonth = nextDayDateTime.getMonth() + 1;
            let nextDayDateDay = nextDayDateTime.getDate();
            if (nextDayDateMonth.toString().length < 2) {
                nextDayDateMonth = "0" + nextDayDateMonth;
            }
            if (nextDayDateDay.toString().length < 2) {
                nextDayDateDay = "0" + nextDayDateDay;
            }
            // Get finished date format
            const nextDayDateTimestamp = `${nextDayDateYear}-${nextDayDateMonth}-${nextDayDateDay}T${endTime}`;

            // Repeating shift, construct shift ID from time, client, caretaker
            const shiftRepeatID = `${currDate.getTime()}--${chosenClient}:${chosenCaretaker}`;
            
            // date in a Day object
            let dateInADay = new Date(startDatetime);

            // date in a Day End time object
            let dateInADayEndTime = new Date(nextDayDateTimestamp);

            // Get repeat end date
            let repeatEndDate = document.getElementById("repeatEndDate").value;
            
            // Construct a repeatEndDateObject, increase day by 1 to make inclusive 
            let repeatEndDateObject = new Date(repeatEndDate);
            repeatEndDateObject.setDate(repeatEndDateObject.getDate() + 1);
            
            // Get day of the week repeat values
            let mondayRepeat = document.getElementById("mondayRepeat").checked;
            let tuesdayRepeat = document.getElementById("tuesdayRepeat").checked;
            let wednesdayRepeat = document.getElementById("wednesdayRepeat").checked;
            let thursdayRepeat = document.getElementById("thursdayRepeat").checked;
            let fridayRepeat = document.getElementById("fridayRepeat").checked;
            let saturdayRepeat = document.getElementById("saturdayRepeat").checked;
            let sundayRepeat = document.getElementById("sundayRepeat").checked;

            // Array to hold day of the week repeat values
            let daysOfTheWeekRepeatArray = [];
            daysOfTheWeekRepeatArray.push(sundayRepeat);
            daysOfTheWeekRepeatArray.push(mondayRepeat);
            daysOfTheWeekRepeatArray.push(tuesdayRepeat);
            daysOfTheWeekRepeatArray.push(wednesdayRepeat);
            daysOfTheWeekRepeatArray.push(thursdayRepeat);
            daysOfTheWeekRepeatArray.push(fridayRepeat);
            daysOfTheWeekRepeatArray.push(saturdayRepeat);

            let shiftCount = 0;

            // Get the repeat frequency of the shift
            let repeatFrequency = document.getElementById("repeatFreqSelect").value;
          
            // Repeating overnight shift with a flat fee
            if (isFlatFee) {
                let flatFeeCaretaker = document.getElementById("flatFeeInputCaretaker").value;
                let flatFeeClient = document.getElementById("flatFeeInputClient").value;

                let shiftObj = {
                    chosenClient: chosenClient, 
                    chosenCaretaker: chosenCaretaker, 
                    startTimeSecs: startTimeSecs, 
                    startDatetime: startDatetime, 
                    endDatetime: nextDayDateTimestamp, 
                    shiftComments: shiftComments, 
                    caretakerColor: caretakerColor, 
                    shiftRepeat: true, 
                    shiftRepeatID: shiftRepeatID, 
                    isFlatFeeShift: true, 
                    flatFeeCaretaker: flatFeeCaretaker, 
                    shiftMileage: shiftMileage, 
                    flatFeeClient: null
                }
                
                shiftsToAdd.push(shiftObj);


               // props.addShift(chosenClient, chosenCaretaker, startTimeSecs, startDatetime, nextDayDateTimestamp, shiftComments, caretakerColor, true, shiftRepeatID, true, flatFeeCaretaker, shiftMileage);
            } else { // Repeating overnight shift without a flat fee

                let shiftObj = {
                    chosenClient: chosenClient, 
                    chosenCaretaker: chosenCaretaker, 
                    startTimeSecs: startTimeSecs, 
                    startDatetime: startDatetime, 
                    endDatetime: nextDayDateTimestamp, 
                    shiftComments: shiftComments, 
                    caretakerColor: caretakerColor, 
                    shiftRepeat: true, 
                    shiftRepeatID: shiftRepeatID, 
                    isFlatFeeShift: false, 
                    flatFeeCaretaker: null, 
                    shiftMileage: shiftMileage, 
                    flatFeeClient: null
                }
                
                shiftsToAdd.push(shiftObj);

               // props.addShift(chosenClient, chosenCaretaker, startTimeSecs, startDatetime, nextDayDateTimestamp, shiftComments, caretakerColor, true, shiftRepeatID, false, null, shiftMileage);
            }

            
            // Loop for remaining shifts in range
            while (dateInADay.getTime() <= repeatEndDateObject.getTime()) {

                shiftCount++;

                // If every other week, incr. by 8 else by 1
                if (repeatFrequency == "Every Other Week" && shiftCount % 7 == 0) {
                    dateInADay.setDate(dateInADay.getDate() + 8);
                    dateInADayEndTime.setDate(dateInADayEndTime.getDate() + 8);
                } else {
                    dateInADay.setDate(dateInADay.getDate() + 1);
                    dateInADayEndTime.setDate(dateInADayEndTime.getDate() + 1);

                }

                // set startTimeSecs to the current date's time
                startTimeSecs = dateInADay.getTime();

                // If the day of the week repeat value is checked
                if (daysOfTheWeekRepeatArray[dateInADay.getDay()] == true) {

                    // build a date object from the current date
                    let dateInADayYear = dateInADay.getFullYear();
                    let dateInADayMonth = dateInADay.getMonth() + 1;
                    let dateInADayDate = dateInADay.getDate();
                    let dateInADayHours = dateInADay.getHours();
                    let dateInADayMins = dateInADay.getMinutes();

                    if (dateInADayDate.toString().length < 2) {
                        dateInADayDate = "0" + dateInADayDate;
                    }
                    if (dateInADayMonth.toString().length < 2) {
                        dateInADayMonth = "0" + dateInADayMonth;
                    }
                    if (dateInADayHours.toString().length < 2) {
                        dateInADayHours = "0" + dateInADayHours;
                    }
                    if (dateInADayMins.toString().length < 2) {
                        dateInADayMins = "0" + dateInADayMins;
                    }
                    
                    // Build the date format
                    let dateInADayFormat = `${dateInADayYear}-${dateInADayMonth}-${dateInADayDate}T${dateInADayHours}:${dateInADayMins}`;
                    
                    // Build the end date time format
                    let dateInADayEndTimeYear = dateInADayEndTime.getFullYear();
                    let dateInADayEndTimeMonth = dateInADayEndTime.getMonth() + 1;
                    let dateInADayEndTimeDate = dateInADayEndTime.getDate();
                    let dateInADayEndTimeHours = dateInADayEndTime.getHours();
                    let dateInADayEndTimeMins = dateInADayEndTime.getMinutes();
                    if (dateInADayEndTimeDate.toString().length < 2) {
                        dateInADayEndTimeDate = "0" + dateInADayEndTimeDate;
                    }
                    if (dateInADayEndTimeMonth.toString().length < 2) {
                        dateInADayEndTimeMonth = "0" + dateInADayEndTimeMonth;
                    }
                    if (dateInADayEndTimeHours.toString().length < 2) {
                        dateInADayEndTimeHours = "0" + dateInADayEndTimeHours;
                    }
                    if (dateInADayEndTimeMins.toString().length < 2) {
                        dateInADayEndTimeMins = "0" + dateInADayEndTimeMins;
                    }

                    // Get final end time format
                    const dateInADayEndTimeFormat = `${dateInADayEndTimeYear}-${dateInADayEndTimeMonth}-${dateInADayEndTimeDate}T${dateInADayEndTimeHours}:${dateInADayEndTimeMins}`;
                    


                    if (isFlatFee) {

                        let flatFeeCaretaker = document.getElementById("flatFeeInputCaretaker").value;
                        let flatFeeClient = document.getElementById("flatFeeInputClient").value;
                        
                        let shiftObj = {
                            chosenClient: chosenClient, 
                            chosenCaretaker: chosenCaretaker, 
                            startTimeSecs: startTimeSecs, 
                            startDatetime: dateInADayFormat, 
                            endDatetime: dateInADayEndTimeFormat, 
                            shiftComments: shiftComments, 
                            caretakerColor: caretakerColor, 
                            shiftRepeat: true, 
                            shiftRepeatID: shiftRepeatID, 
                            isFlatFeeShift: true, 
                            flatFeeCaretaker: flatFeeCaretaker, 
                            shiftMileage: shiftMileage, 
                            flatFeeClient: flatFeeClient
                        }

                        shiftsToAdd.push(shiftObj);

                        // props.addShift(chosenClient, chosenCaretaker, startTimeSecs, dateInADayFormat, dateInADayEndTimeFormat, shiftComments, caretakerColor, true, shiftRepeatID, true, flatFeeCaretaker, shiftMileage, flatFeeClient);
                    } else {

                        let shiftObj = {
                            chosenClient: chosenClient, 
                            chosenCaretaker: chosenCaretaker, 
                            startTimeSecs: startTimeSecs, 
                            startDatetime: dateInADayFormat, 
                            endDatetime: dateInADayEndTimeFormat, 
                            shiftComments: shiftComments, 
                            caretakerColor: caretakerColor, 
                            shiftRepeat: true, 
                            shiftRepeatID: shiftRepeatID, 
                            isFlatFeeShift: false, 
                            flatFeeCaretaker: null, 
                            shiftMileage: shiftMileage, 
                            flatFeeClient: null
                        }

                        shiftsToAdd.push(shiftObj);

                        // props.addShift(chosenClient, chosenCaretaker, startTimeSecs, dateInADayFormat, dateInADayEndTimeFormat, shiftComments, caretakerColor, true, shiftRepeatID, false, null, shiftMileage, null);
                    }
                   
                }
            }
        }
        
        else {

            if (isFlatFee) {
                let flatFeeCaretaker = document.getElementById("flatFeeInputCaretaker").value;
                let flatFeeClient = document.getElementById("flatFeeInputClient").value;

                let shiftObj = {
                    chosenClient: chosenClient, 
                    chosenCaretaker: chosenCaretaker, 
                    startTimeSecs: startTimeSecs, 
                    startDatetime: startDatetime, 
                    endDatetime: endDatetime, 
                    shiftComments: shiftComments, 
                    caretakerColor: caretakerColor, 
                    shiftRepeat: false, 
                    shiftRepeatID: null, 
                    isFlatFeeShift: true, 
                    flatFeeCaretaker: flatFeeCaretaker, 
                    shiftMileage: shiftMileage, 
                    flatFeeClient: flatFeeClient
                }

                shiftsToAdd.push(shiftObj);

               // props.addShift(chosenClient, chosenCaretaker, startTimeSecs, startDatetime, endDatetime, shiftComments, caretakerColor, false, null, true, flatFeeCaretaker, shiftMileage, flatFeeClient);
            } else {

                let shiftObj = {
                    chosenClient: chosenClient, 
                    chosenCaretaker: chosenCaretaker, 
                    startTimeSecs: startTimeSecs, 
                    startDatetime: startDatetime, 
                    endDatetime: endDatetime, 
                    shiftComments: shiftComments, 
                    caretakerColor: caretakerColor, 
                    shiftRepeat: false, 
                    shiftRepeatID: null, 
                    isFlatFeeShift: false, 
                    flatFeeCaretaker: null, 
                    shiftMileage: shiftMileage, 
                    flatFeeClient: null
                }

                shiftsToAdd.push(shiftObj);

                //props.addShift(chosenClient, chosenCaretaker, startTimeSecs, startDatetime, endDatetime, shiftComments, caretakerColor, false, null, false, null, shiftMileage, null);
            }

            
        }
        
        props.addShift(shiftsToAdd);

        alert("Shifts added successfully");
    }

    const getClients = () => {
        var select = document.getElementById("clientSelect");
        select.innerHTML = '';

        let sorted = props.clientList.sort( function(a, b) {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        })

        for (const clientName of sorted) {
            var option = document.createElement("option");
            option.text = clientName.name;
            select.appendChild(option);
        }
    } 

    const getCaretakers = () => {
        var select = document.getElementById("caretakerSelect");
        select.innerHTML = '';
        
        let sorted = props.caretakerList.sort( function(a, b) { 
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
        
        for (const caretakerName of sorted) {
            var option = document.createElement("option");
            option.text = caretakerName.name;
            select.appendChild(option);
        }
    } 

    const clearErrorDiv = () => {
        document.getElementById("endTimeErrorDiv").innerHTML = "";
        document.getElementById("endTimeErrorDiv").style.height = "0vh";
    }

    

    return(
        <div className="addShiftBoxDiv" id="addShiftBoxDiv">
            <a name="addShiftTarget"></a>
            <h2 id="addShiftH2">Add Shift</h2>
            <div className="formSep"></div>
            <div className="addShiftFormDiv">

                <div className="optionSelectDiv" id="clientDiv">
                    <label htmlFor="clientSelect">Client: </label>
                    <select id="clientSelect">
                    </select>
                </div>

                <div className="formSep"></div>

                <div className="optionSelectDiv" id="caretakerDiv">
                    <label htmlFor="caretakerSelect">Caretaker: </label>
                    <select id="caretakerSelect">
                    
                    </select>
                </div>
                <div className="formSep"></div>
                <div className="optionSelectDiv" id="shiftStartDateDiv">
                    <label htmlFor="shiftStartDate">Start Date: </label>
                    <input type="date" id="shiftStartDate" name="shiftStartDate" onChange={() => {
                        //document.getElementById("shiftEndDate").value = null;
                        document.getElementById("shiftEndDate").value = document.getElementById("shiftStartDate").value
                        }
                    }/>
                </div>

                <div className="formSep"></div>

                <div className="optionSelectDiv" id="shiftStartTimeDiv">
                    <label htmlFor="shiftStartTime">Start Time: </label>
                    <input type="time" id="shiftStartTime" 
                    name="shiftStartTime"/>
                </div>

                <div className="formSep"></div>

                <div className="optionSelectDiv" id="shiftEndDateDiv">
                    <label htmlFor="shiftEndDate">End Date: </label>
                    <input type="date" id="shiftEndDate" 
                    name="shiftEndDate" onInput={clearErrorDiv}/>
                </div>

                <div className="formSep"></div>

                <div className="optionSelectDiv" id="shiftEndTimeDiv">
                    <label for="shiftEndTime">End Time: </label>
                    <input type="time" id="shiftEndTime" 
                    name="shiftEndTime" onInput={clearErrorDiv}/>
                    <div id="endTimeErrorDiv"></div>
                </div>

                <div className="formSep"></div>
                
                
                <label for="shiftComments">Comments: </label>
                <textarea id="shiftComments" name="shiftComments" placeholder="Enter any short comments here">
                
                </textarea>

                <div className="formSep"></div>

                <label for="mileage">Mileage Money: </label>
                <input type="number" name="mileage" id="mileage"></input>

                <div className="formSep"></div>

                <button id="flatFeeSelect" name="flatFeeSelect" onClick={() => {
                        if (props.renderFlatFeeOptions == true && props.renderRepeatOptions == true) {
                            document.getElementById("addShiftBoxDiv").style.height = "185vh";
                            document.getElementById("flatFeeOptionsDiv").style.height = "0vh";
                            props.setRenderFlatFeeOptions(false);
                            
                        } else if (props.renderFlatFeeOptions == false && props.renderRepeatOptions == true) { 
                            document.getElementById("addShiftBoxDiv").style.height = "205vh";
                            document.getElementById("flatFeeOptionsDiv").style.height = "20vh";
                            props.setRenderFlatFeeOptions(true);

                        } else if (props.renderFlatFeeOptions == true && props.renderRepeatOptions == false) {
                            document.getElementById("addShiftBoxDiv").style.height = "120vh";
                            document.getElementById("flatFeeOptionsDiv").style.height = "0vh";
                            props.setRenderFlatFeeOptions(false);
                        } else if (props.renderFlatFeeOptions == false && props.renderRepeatOptions == false) {
                            document.getElementById("addShiftBoxDiv").style.height = "140vh";
                            document.getElementById("flatFeeOptionsDiv").style.height = "20vh";
                            props.setRenderFlatFeeOptions(true);
                        }
                    }}>
                        Flat Fee?
                    </button>
                    <div className="formSep"></div>
                    <div id="flatFeeOptionsDiv">
                        {props.renderFlatFeeOptions === true && <div><label htmlFor="flatfeeInputCaretaker">Flat Fee Amount - Caretaker: </label><input id="flatFeeInputCaretaker" type="number"></input></div>}
                        {props.renderFlatFeeOptions === true && <div><label htmlFor="flatfeeInputClient">Flat Fee Amount - Client: </label><input id="flatFeeInputClient" type="number"></input></div>}
                        
                    </div>

                <button id="repeatSelect" name="repeatSelect" onClick={() => {
                        if (props.renderFlatFeeOptions == true && props.renderRepeatOptions == true) {
                            document.getElementById("addShiftBoxDiv").style.height = "140vh";
                            document.getElementById("repeatShiftOptionsDiv").style.height = "0vh";
                            props.setRenderRepeatOptions(false);

                        } else if (props.renderFlatFeeOptions == false && props.renderRepeatOptions == true) { 
                            document.getElementById("addShiftBoxDiv").style.height = "120vh";
                            document.getElementById("repeatShiftOptionsDiv").style.height = "0vh";
                            props.setRenderRepeatOptions(false);

                        } else if (props.renderFlatFeeOptions == true && props.renderRepeatOptions == false) {
                            document.getElementById("addShiftBoxDiv").style.height = "205vh";
                            document.getElementById("repeatShiftOptionsDiv").style.height = "60vh";
                            props.setRenderRepeatOptions(true);
                        } else if (props.renderFlatFeeOptions == false && props.renderRepeatOptions == false) {
                            document.getElementById("addShiftBoxDiv").style.height = "185vh";
                            document.getElementById("repeatShiftOptionsDiv").style.height = "60vh";
                            props.setRenderRepeatOptions(true);
                        }
                        
                    }}>
                        Repeating Shift?
                    </button>
                    <div className="formSep"></div>
            </div>

            

            <div id="repeatShiftOptionsDiv">
                {props.renderRepeatOptions === true && <RepeatOptions />}
            </div>

            <Button onClick={() => gatherFields()}
            type="button"
            buttonStyle="btn--success--solid"
            buttonSize="btn--medium"
            >
              Confirm Shift
            </Button>

        </div>
    )

}