import './addAppointmentBox.css';
import { Button } from './Button';
import React, { useEffect } from 'react';


export const AddAppointmentBox = (props) => {

    useEffect(() => {
        getClientsForApt();
        
    })

    const gatherFields = () => {
        let aptClientSelect = document.getElementById('aptClientSelect').value;
        let aptStartDate = document.getElementById('appointmentStartDate').value;
        let aptStartTime = document.getElementById('appointmentStartTime').value;
        console.log("apt start time");
        let aptHourSplit = aptStartTime.toString().split(':')[0];
        let aptMinSplit = aptStartTime.toString().split(':')[1];
        aptHourSplit = Number(aptHourSplit) + 4;
        if (aptHourSplit.toString().length < 2) {
            aptHourSplit = "0" + aptHourSplit;
        }
        let aptStartDatetime = aptStartDate + "T" + aptStartTime;
        let aptEndDatetime = `${aptStartDate}T${aptHourSplit}:${aptMinSplit}`;
        let aptDetails = document.getElementById('appointmentDetails').value;
        let aptLabel = document.getElementById("appointmentLabelInput").value;

        let addedBy = props.userList.filter(e => e.email == props.user.email)[0].name;
        console.log("added by");
        console.log(addedBy);
        
        props.addAppointment(aptClientSelect, aptStartDatetime, aptEndDatetime, aptDetails, addedBy, aptLabel);
        alert("Appointment added successfully");
    }

    const getClientsForApt = () => {
        var aptClientSelect = document.getElementById("aptClientSelect");
        aptClientSelect.innerHTML = '';
        for (const clientName of props.clientList) {
            var option = document.createElement("option");
            option.text = clientName.name;
            aptClientSelect.appendChild(option);
        }
    }

    return(

        <div className="addAppointmentBoxDiv">
            <a name="addAppointmentTarget"></a>
            <h2>Add Appointment</h2>
            <div className="formSep"></div>
            <div className="addAppointmentFormDiv">
                <div className="clientDiv">
                <label id="clientLabel">Client: </label>
                <select id="aptClientSelect">
                    
                </select>
                </div>
                <div className="formSep"></div>
                <label htmlFor="appointmentStartDate">Start Date: </label>
                <input type="date" id="appointmentStartDate" 
                name="appointmentStartDate"/>
                <div className="formSep"></div>
                <label htmlFor="appointmentStartTime">Start Time: </label>
                <input type="time" id="appointmentStartTime" 
                name="appointmentStartTime"/>
                <div className="formSep"></div>

                <label for="appointmentLabelInput" id="appointmentLabelInputLabel">Appointment Label: </label>
                <input type="text" id="appointmentLabelInput" placeholder="Heart Doctor, Dentist, Eye Doctor, etc." />

                <div className="formSep"></div>

                <label for="appointmentDetails">Details: </label>
                <textarea id="appointmentDetails" name="appointmentDetails"  placeholder="Enter appointment details and comments here">
                
                </textarea>
            <div className="formSep"></div>
            <Button onClick={() => gatherFields()}
            type="button"
            buttonStyle="btn--success--solid"
            buttonSize="btn--medium"
            >
              Confirm Appointment
            </Button>
            </div>
        </div>
    )

}