import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default function ConfirmDeleteAppointmentDialog(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.displayConfirmDeleteAppointmentDialog);
  })

  const handleClose = () => {
    props.setDisplayConfirmDeleteAppointmentDialog(false);
  };

  async function handleDelete() {
    console.log("apt clicked");
    props.deleteApt();
    handleClose();
  }


  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the appointment?
          </DialogContentText>
         

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        
        </DialogActions>
      </Dialog>
    </div>
  );
}