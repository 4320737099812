import React from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin for month view!
import timeGridPlugin from '@fullcalendar/timegrid' // a plugin for week view!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import { formatDate } from '@fullcalendar/core'
import './myAppointments.css'
import { Button } from './components/Button';
import firebase from 'firebase/app';
import 'firebase/firestore';
import userEvent from '@testing-library/user-event'



export default class MyAppointments extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      aptEventList: props.aptEventList,
      displayAptDialog: props.displayAptDialog,
      user: props.user
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ aptEventList: nextProps.aptEventList, displayAptDialog: nextProps.displayAptDialog, user: nextProps.user });  
  }


  render() {
        

    return (

      <div className='aptCalendarDiv'>
       
      <a name='aptCalendarTarget'></a>
      <h1 id="appointmentsH1">Appointments</h1>
      <div id="aptCalendarDiv">
      <FullCalendar
        plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}

        // Changes the Hours Div when the date on the calendar is changed
        datesSet={(dateInfo) => {
          console.log("DATEINFO")
          console.log(dateInfo.start);
          console.log(dateInfo.end);
          
          //const dateStart = (dateInfo.start).split("00:00:00")[0];
          //this.props.getTimeAndDate(dateInfo.start.toLocaleDateString(), dateInfo.end.toLocaleDateString(), dateInfo.startStr, dateInfo.endStr);

        }}
        headerToolbar={{ center: 'dayGridMonth,timeGridWeek'} } // Defines the buttons and title at top of calendar.
        views={{
          dayGridMonth: { // name of view
            titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' }
            // other view-specific options here
          },
          timeGridWeek: {
            titleFormat: { year: 'numeric', month: 'short', day: 'numeric' }
          }
        }}
        firstDay="5"
        eventTimeFormat={{ // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          meridiem: true
        }}
        eventContent={renderEventContent}
        initialView="timeGridWeek"
        displayEventEnd='true'
        events={this.state.aptEventList}
        eventClick={this.handleEventClick}
        eventBackgroundColor={'#7460f3'}
        height="150vh"
      
      />
      </div>
      </div>
      
    )
  }


  handleEventClick = (arg) => {
    console.log("APT ARG!");
  console.log(arg);
  console.log(arg.event.extendedProps);
  this.props.setAptClickedObject(arg);
  this.props.setDisplayEditAppointmentDialog(true);
    
  } 

}





function renderEventContent(eventInfo) {
    

  const prettifyTime = (time) => {
    let timeAfterSplit = time.split("T")[1];
    let hourVal = timeAfterSplit.split(":")[0];
    console.log("TIME AFTER SPLIT");
    console.log(timeAfterSplit);
    let minVal = timeAfterSplit.split(":")[1];
    console.log(minVal);
    let isPM = false;
    if (hourVal >= 13) {
      hourVal -= 12;
      isPM = true;
    }
    if (hourVal == 12) {
      isPM = true;
    }
    return isPM ? `${hourVal}:${minVal} PM` : `${hourVal}:${minVal} AM`;
  }


    return (
      
      <>
      
        
        <p id="timeAptLabel"><b>{eventInfo.timeText.split("-")[0]}</b></p>
        
        <div className="clientAptDiv">
        <p id="clientAptLabel">{eventInfo.event.extendedProps.client}</p>
        </div>
        <div classname="aptDescriptionDiv">
          <div>{eventInfo.event.extendedProps.aptLabel} </div>
          <p>Click to see details</p>
        </div>
        
      </>
    )
  }

