import React, { useEffect } from 'react';
import './raiseBox.css';
import { Button } from './Button';

export const RaiseBox = (props) => {

    useEffect(() => {
        getCaretakersRaiseBox();
    }, [props.caretakerList])

    const getCaretakersRaiseBox = () => {
        var select = document.getElementById("caretakerSelectRaiseBox");
        select.innerHTML = '';
        for (const caretakerName of props.caretakerList) {
            var option = document.createElement("option");
            option.text = caretakerName.name;
            select.appendChild(option);
        }
    } 

    const gatherFieldsRaiseBox = () => {
        console.log("so far");

        let caretakerReceivingRaise = document.getElementById("caretakerSelectRaiseBox").value;

        let raiseEffectiveDate = document.getElementById("dateOfRaise").value;
        let raiseEffectiveDateObj = new Date(raiseEffectiveDate);
        let raiseEffectTime = raiseEffectiveDateObj.getTime();

        let newPay = document.getElementById("newPayRate").value;

        console.log(caretakerReceivingRaise);
        console.log(raiseEffectiveDateObj.getTime());
        console.log(newPay);

        const caretakerIDEditRB = props.caretakerList.filter(e => e.name === caretakerReceivingRaise)[0].docID;

        props.firestore.collection("caretakers").doc(caretakerIDEditRB).get().then((doc) => {

            if (doc.exists) {

                let oldPayArray = doc.data().pay;

                let oldPay = oldPayArray[0];

                oldPayArray[raiseEffectTime] = oldPay;

                oldPayArray[0] = newPay;

                props.firestore.collection("caretakers").doc(caretakerIDEditRB).update({pay : oldPayArray})


            }
        })

        alert("Caretaker pay updated successfully");
    }


    return (
        <div className='raiseBoxDiv'>
            <a name='raiseBoxTarget' id ='raiseBoxTarget'></a>
            <h2>Raise Box</h2>
            
            <div className="formSep"></div>

            <div className="optionSelectDiv" id="caretakerDivRaiseBox">
            <label htmlFor="caretakerSelectRaiseBox">Caretaker to apply raise to: </label>
                    <select id="caretakerSelectRaiseBox">
                    
                    </select>
            </div>

            <div className="formSep"></div>

            <label htmlFor="dateOfRaise">Date raise should take effect: </label>
            <input type="date" id="dateOfRaise"></input>

            <div className="formSep"></div>

            <label htmlFor="newPayRate">New pay rate: </label>
            <input type="number" id="newPayRate"></input>

            <div className="formSep"></div>

            <Button onClick={() => gatherFieldsRaiseBox()}
            type="button"
            buttonStyle="btn--success--solid"
            buttonSize="btn--medium"
            >
              Confirm Raise
            </Button>

        </div>
    )
}