import React from "react";
import "./addCaretakerBox.css";
import { Button } from "./Button";

export const AddCaretakerBox = (props) => {
  async function gatherFields() {
    let caretakerName = document
      .getElementById("caretakerNameInput")
      .value.trim();
    let caretakerEmail = document
      .getElementById("caretakerEmailInput")
      .value.trim()
      .toLowerCase();
    let caretakerPay = document
      .getElementById("caretakerPayInput")
      .value.trim();
    let caretakerColor = document.getElementById("caretakerColorInput").value;
    console.log(caretakerName);
    console.log(caretakerEmail);
    console.log(caretakerPay);
    await props.addCaretaker(
      caretakerName,
      caretakerEmail,
      caretakerPay,
      caretakerColor
    );
    await props.addUser(caretakerName, caretakerEmail, "caretaker");
    alert("Caretaker added successfully");
  }

  return (
    <div className="addCaretakerDiv">
      <a name="addCaretakerTarget"></a>
      <h2>Add Caretaker</h2>
      <div className="formSep"></div>
      <div className="addCaretakerFormDiv">
        <div className="caretakerInfoDiv">
          <label for="caretakerNameInput" id="caretakerNameLabel">
            Name:{" "}
          </label>
          <input type="text" id="caretakerNameInput" required></input>
          <div className="formSep"></div>
          <label for="caretakerEmailInput" id="caretakerEmailLabel">
            Email:{" "}
          </label>
          <input type="email" id="caretakerEmailInput" required></input>
          <div className="formSep"></div>
          <label for="caretakerPayInput" id="caretakerPayLabel">
            Pay:{" "}
          </label>
          <input type="number" id="caretakerPayInput" required></input>
          <div className="formSep"></div>
          <label for="caretakerColorInput" id="caretakerColorLabel">
            Color:{" "}
          </label>
          <input type="color" id="caretakerColorInput"></input>

          <div className="formSep"></div>
          <div className="formSep"></div>
        </div>
        <Button
          onClick={() => gatherFields()}
          type="button"
          buttonStyle="btn--success--solid"
          buttonSize="btn--medium"
        >
          Add Caretaker
        </Button>
      </div>
    </div>
  );
};
