import React from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin for month view!
import timeGridPlugin from '@fullcalendar/timegrid' // a plugin for week view!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import { formatDate } from '@fullcalendar/core'
import './myCalendar.css'
import { Button } from './components/Button';
import firebase from 'firebase/app';
import 'firebase/firestore';
import userEvent from '@testing-library/user-event'
import CaretakerFilter from './components/CaretakerFilter'


export default class MyCalendar extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      eventList: props.eventList,
      displayDialog: props.displayDialog,
      user: props.user,
      filterSelected: props.filterSelected
    };
  }

  // need to update to new hook
  componentWillReceiveProps(nextProps) {
    this.setState({ eventList: nextProps.eventList, displayDialog: nextProps.displayDialog, user: nextProps.user, filterSelected: nextProps.filterSelected });  
  }

 
    
 

  render() {
        

  

    return (

      <div className='calendarDiv'>
      <a name='calendarTarget'></a>
      <FullCalendar
        plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}

        // Changes the Hours Div when the date on the calendar is changed
        datesSet={(dateInfo) => {
          console.log("DATEINFO")
          console.log(dateInfo.start);
          console.log(dateInfo.end);
          
          //const dateStart = (dateInfo.start).split("00:00:00")[0];
          this.props.getTimeAndDate(dateInfo.start.toLocaleDateString(), dateInfo.end.toLocaleDateString(), dateInfo.startStr, dateInfo.endStr);

        }}
        headerToolbar={{ center: 'dayGridMonth,timeGridWeek'} } // Defines the buttons and title at top of calendar.
        views={{
          dayGridMonth: { // name of view
            titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' }
            // other view-specific options here
          },
          timeGridWeek: {
            titleFormat: { year: 'numeric', month: 'short', day: 'numeric' }
          }
        }}

        firstDay="5"
        eventTimeFormat={{ // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          meridiem: true
        }}
        eventContent={renderEventContent}
        initialView="timeGridWeek"
        displayEventEnd='true'
        events={this.state.eventList}
        eventClick={this.handleEventClick}
        eventBackgroundColor={'#7460f3'}
        height="150vh"
      
      />
      </div>
      
    )
  }


  handleEventClick = (arg) => {
    console.log("ARG!");
    console.log(arg);
    console.log(arg.event.extendedProps);
    this.props.setEventClickedObject(arg);
    this.props.setDisplayDialog(true);
    
  } 

}





function renderEventContent(eventInfo) {
    

  const prettifyTime = (time) => {
  //  console.log("TIME!!!");
  //  console.log(time);
    let timeAfterSplit = time.split("T")[1];
    let hourVal = timeAfterSplit.split(":")[0];
 //   console.log("TIME AFTER SPLIT");
  //  console.log(timeAfterSplit);
    let minVal = timeAfterSplit.split(":")[1];
  //  console.log(minVal);
    let isPM = false;
    if (hourVal >= 13) {
      hourVal -= 12;
      isPM = true;
    }
    if (hourVal == 12) {
      isPM = true;
    }
    return isPM ? `${hourVal}:${minVal} PM` : `${hourVal}:${minVal} AM`;
  }


    return (
      
      <>
        {console.log("eventInfo")}
        <div id="dayAndDateLabel">{String(eventInfo.event.start).split("202")[0]}</div>
        <div id="timeEventLabel">{eventInfo.timeText}{eventInfo.event.extendedProps.editedStartTime != "" && "*"}</div>
        
        <div className="clientAndCaretakerDiv">
        <div id="clientCaretakerEventLabel"><div id="clientLabelRender"><i>{eventInfo.event.extendedProps.client}</i></div>
          <div id="dashP">{" - "}</div><div id="caretakerLabelRender"><b>{eventInfo.event.extendedProps.caretaker}</b></div></div>
        </div>
        <div classname="descriptionDiv">
          <div>{eventInfo.event.extendedProps.comments}</div>
        </div>
        
      </>
    )
  }

