import { objectExpression } from '@babel/types';
import React, { useEffect, useState } from 'react';
import './caretakerSelect.css';

export const CaretakerSelect = (props) => {


    useEffect(() => {
        populateCaretakerDropdown();
    });

    const populateCaretakerDropdown = () => {
        console.log("PCD!")
        var csd = document.getElementById("caretakerSelectDropdown" + props.caretakerSelectID);
        
        csd.innerHTML = "";

        for (var i = 0; i < props.caretakerListNames.length; i++) {
            var caretakerNameOptionElement = document.createElement("option");
            caretakerNameOptionElement.text = props.caretakerListNames[i];
            csd.appendChild(caretakerNameOptionElement);
        }

        for (var i = 0; i < props.caretakerListNames.length; i++) {
            
            var currCaretakerName = props.caretakerListNames[i];
            
            if (props.eventClickedObj && props.eventClickedObj.extendedProps.caretaker === currCaretakerName) {
                csd.selectedIndex = i;
                break;
            } 
            
        }
    }

    return (
        <div>
            <select id={"caretakerSelectDropdown" + props.caretakerSelectID}></select>
        </div>
    )
}