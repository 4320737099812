import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './deleteClientDialog.css'

export default function DeleteClientDialog(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.displayDeleteClientDialog);
  })

  const handleClose = () => {
    props.setDisplayDeleteClientDialog(false);
  };

  async function handleDeactivate() {

    let deactivDate = document.getElementById("deactivDateInputClient").value;

    deactivDate = deactivDate == "" ? new Date().getTime : deactivDate;

    console.log(deactivDate);

    await props.firestore.collection("clients").doc(props.clientToDeleteID).update(
      {
        isDeactivated: true,
        deactivationTime: deactivDate
      }
    );

    props.setClientToDeleteName("");
    props.setClientToDeleteID("");
    await props.InitialLoadClients();


    alert("Client successfully deactivated.");
    
    //props.InitialLoadShifts();
    handleClose();
  }

  const handleDelete = () => {

    let enteredName = document.getElementById("clientDeleteCheck").value.toLowerCase();
    let checkName = props.clientToDeleteName.toLowerCase();

    if (enteredName === checkName) {
      props.firestore.collection("clients").doc(props.clientToDeleteID).delete();
      alert("Client successfully deleted. You may need to refresh the page to see changes.");
    } else {
      alert("ERROR - Entered name did not match client's name. Delete aborted.")
    }

    props.InitialLoadClients();
    props.setClientToDeleteName("");
    props.setClientToDeleteID("");
    handleClose();
  }


  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <div className='titleAndX'>
        <DialogTitle id="form-dialog-title">Confirm Client Delete</DialogTitle>

          <Button onClick={handleClose} id="cancelDeleteClient">
             X
          </Button>
          </div>
        

        <DialogContent>

          <DialogContentText>

          <h3>Option 1 - Deactivate</h3>
          Choose 'Deactivate' if you wish to preserve the financial information for {props.clientToDeleteName}. Select the date the deactivation should take effect.

          </DialogContentText>

          <div className='deactivateDiv'>

            <input type='date' id='deactivDateInputClient'></input> &nbsp; &nbsp;

            <Button onClick={handleDeactivate} id="deactivateClient">
              Deactivate
            </Button>
            
          </div>

          

          <br />  
          <br />  



          <DialogContentText>

            <h3>Option 2 - Delete</h3>

            <strong>WARNING!</strong> Deleting will erase <strong>ALL</strong> previous shift and wage information for {props.clientToDeleteName}. <br /><br />

            To <strong>Delete</strong>, enter the client's name (<strong>{props.clientToDeleteName}</strong>), and press <strong>'Confirm Delete'</strong>.
            
            
          </DialogContentText>
         

        </DialogContent>
        <DialogActions>
          

          <input id="clientDeleteCheck"></input>

          <Button onClick={handleDelete} id="confirmDeleteClient">
            Confirm Delete
          </Button>
        
        </DialogActions>
      </Dialog>
    </div>
  );
}