import React from 'react';
import './nav.css';

export const Nav = () => {
    return (
        <div className='Nav'>
            <ul className='navOptionsList'>
                <div id='homeNav'><a href='#homeTarget'>Home</a></div>
                <div id='homeNavSeperator'></div>
                <div className='navOption' id='calendarNav'><a href='#calendarTarget'>Calendar</a></div>
                <div className='navOption' id='addShiftNav'><a href='#addShiftTarget'>Add Shift</a></div>
                <div className='navOption' id='addShiftNav'><a href='#hoursTarget'>Caretaker Hours</a></div>
                <div className='navOption' id='addShiftNav'><a href='#hoursBoxTargetClient'>Client Hours</a></div>
                <div className='navOption' id='aptNav'><a href='#aptCalendarTarget'>Appointments</a></div>
                <div className='navOption' id='addAppointmentNav'><a href='#addAppointmentTarget'>Add Appointment</a></div>
                <div className='navOption' id='addCaretaker'><a href='#addCaretakerTarget'>Add Caretaker</a></div> 
                <div className='navOption' id='editCaretakers'><a href='#editCaretakersTarget'>Edit Caretakers</a></div> 
                <div className='navOption' id='raiseBoxNav'><a href='#raiseBoxTarget'>Give a Raise</a></div> 
                <div className='navOption' id='addClient'><a href='#addClientTarget'>Add Client</a></div> 
                <div className='navOption' id='editClients'><a href='#editClientsTarget'>Edit Clients</a></div>  
                { /* <div className='navOption' id='mileageInput'><a href='#mileageAdminTarget'>Mileage</a></div> */ }
                              
            </ul>
        </div>
    )
}