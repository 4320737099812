import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./deleteClientDialog.css"

export default function DeleteCaretakerDialog(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.displayDeleteCaretakerDialog);
  })

  const handleClose = () => {
    props.setDisplayDeleteCaretakerDialog(false);
  };


  async function handleDeactivate() {

    let deactivDate = document.getElementById("deactivDateInput").value;

    deactivDate = deactivDate == "" ? new Date().getTime : deactivDate;

    console.log(deactivDate);



    await props.firestore.collection("caretakers").doc(props.caretakerToDeleteID).update(
      {
        isDeactivated: true,
        deactivationTime: deactivDate
      }
    );
    console.log("user to delete");
    console.log(props.userToDeleteID);
    await props.firestore.collection("users").doc(props.userToDeleteID).delete(); 
    await props.InitialLoadCaretakers();
    alert("Caretaker successfully deactivated.");
    props.setCaretakerToDeleteName("");
    props.setCaretakerToDeleteID("");
    props.InitialLoadCaretakers();
    //props.InitialLoadShifts();
    handleClose();
  }



  async function handleDelete() {

    console.log("handle delete master event list");
    //console.log(props.masterEventList);
    //let shiftFinder = props.masterEventList.filter(e => e.caretaker == props.caretakerToDeleteName);
    //console.log("shift finder");
    //console.log(shiftFinder);

    let enteredName = document.getElementById("caretakerDeleteCheck").value.toLowerCase();
    let checkName = props.caretakerToDeleteName.toLowerCase();

    if (enteredName === checkName) {
      await props.firestore.collection("caretakers").doc(props.caretakerToDeleteID).delete();
      alert("Caretaker successfully deleted. You may need to refresh the page to see changes.");
      await props.firestore.collection("users").doc(props.userToDeleteID).delete(); 
      await props.InitialLoadCaretakers();
    } else {  
      alert("Error - Entered name did not match caretaker's name. Delete aborted");
    }

    //await props.firestore.collection("caretakers").doc(props.caretakerToDeleteID).delete();
    //console.log("user to delete id");
    //console.log(props.userToDeleteID);

    props.setCaretakerToDeleteName("");
    props.setCaretakerToDeleteID("");
    props.InitialLoadCaretakers();
    //props.InitialLoadShifts();
    handleClose();
  }


  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <div className='titleAndX'>
        <DialogTitle id="form-dialog-title">Confirm Caretaker Delete</DialogTitle>
        <Button onClick={handleClose} id="cancelDeleteCaretaker">
             X
        </Button>

      </div>
        <DialogContent>
          <DialogContentText>

          <h3>Option 1 - Deactivate</h3>

            Choose <strong>'Deactivate'</strong> if you wish to preserve the financial information for {props.caretakerToDeleteName}.

          </DialogContentText>

          <div className='deactivateDiv'>

            <input type='date' id='deactivDateInput'></input> &nbsp; &nbsp;

          <div>
            <Button onClick={handleDeactivate} id="deactivateCaretaker">
              Deactivate
            </Button>
            </div>
          </div>

          <br />
          <br />

          <DialogContentText>
          <h3>Option 2 - Delete</h3>
           
          <strong>WARNING!</strong> Deleting will erase <strong>ALL</strong> previous shift and wage information for {props.caretakerToDeleteName}. <br /><br />

          To <strong>Delete</strong>, enter the caretakers's name (<strong>{props.caretakerToDeleteName}</strong>), and press <strong>'Confirm Delete'</strong>.
            
          </DialogContentText>


         

        </DialogContent>
        <DialogActions>
          
          <input id="caretakerDeleteCheck"></input>
          
          <Button onClick={handleDelete} id="confirmDeleteCaretaker">
            Confirm Delete
          </Button>
        
        </DialogActions>
      </Dialog>
    </div>
  );
}