import React, { useEffect, useState } from 'react';
import './editCaretakersBox.css';
import { Button } from './Button';

export const EditCaretakersBox = (props) => {

    const [showingDeactivated, setShowingDeactivated] =  useState(false);
    
    useEffect(() => {
        getCaretakersForEditBox();
        growBox();
    })


    const growBox = () => {

        if (props.caretakerList.length <= 12) {
            document.getElementById("editCaretakersDiv").style.height = "100vh";
        } else {
            let increaseDifferenceCaretakerNumber = props.caretakerList.length - 12;
            let increaseVH = (increaseDifferenceCaretakerNumber * 7) + 100;
            let increaseVHString = increaseVH + "vh";
            document.getElementById("editCaretakersDiv").style.height = increaseVHString;
        }
        
    }


    const getCaretakersForEditBox = () => {
        
        console.log("Get caretakers")
        const caretakerEditBoxTable = document.getElementById("caretakerEditBoxTable");
        caretakerEditBoxTable.innerHTML = "";

        const caretakerEditBoxLabelsRow = document.createElement("tr");
        const caretakerEditBoxNameLabel = document.createElement("td");
        const caretakerEditBoxEmailLabel = document.createElement("td");
        const caretakerEditBoxPayLabel = document.createElement("td");
        const caretakerEditBoxColorLabel = document.createElement("td");

        caretakerEditBoxNameLabel.innerHTML = "Name";
        caretakerEditBoxEmailLabel.innerHTML = "Email";
        caretakerEditBoxPayLabel.innerHTML = "Pay";
        caretakerEditBoxColorLabel.innerHTML = "Color";

        caretakerEditBoxLabelsRow.appendChild(caretakerEditBoxNameLabel);
        caretakerEditBoxLabelsRow.appendChild(caretakerEditBoxEmailLabel);
        caretakerEditBoxLabelsRow.appendChild(caretakerEditBoxPayLabel);
        caretakerEditBoxLabelsRow.appendChild(caretakerEditBoxColorLabel);

        caretakerEditBoxTable.appendChild(caretakerEditBoxLabelsRow);

        for (const caretakerName of props.caretakerList) {

            
            const caretakerEditBoxRow = document.createElement("tr");
            caretakerEditBoxRow.id = "caretakerRow" + caretakerName.name;

            const caretakerNameData = document.createElement("td");
            caretakerNameData.innerHTML = caretakerName.name;
            caretakerNameData.id = "caretakerNameData" + caretakerName.name;
            caretakerNameData.className = "caretakerNameData";
            
            const caretakerEmailData = document.createElement("td");
            const caretakerEmailInput = document.createElement("input");
            caretakerEmailInput.value = caretakerName.email;
            caretakerEmailInput.id = "caretakerEmailInput" + caretakerName.name;
            caretakerEmailInput.className = "caretakerEmailInput";
            caretakerEmailData.appendChild(caretakerEmailInput);

            const caretakerPayData = document.createElement("td");
            //const caretakerPayInput = document.createElement("input");
            //caretakerPayInput.id = "caretakerPayInput" + caretakerName.name;
            //caretakerPayInput.className = "caretakerPayInput"
            //caretakerPayInput.value = caretakerName.pay;
            //caretakerPayData.appendChild(caretakerPayInput);
            caretakerPayData.innerHTML = caretakerName.pay[0];

            const caretakerColorData = document.createElement("td");
            const caretakerColorInput = document.createElement("input");
            caretakerColorInput.id ="caretakerColorInput" + caretakerName.name;
            caretakerColorInput.className = "caretakerColorInput";
            caretakerColorInput.type = "color";
            caretakerColorInput.value = caretakerName.color;
            caretakerColorData.appendChild(caretakerColorInput);

            const updateCaretakerButtonTD = document.createElement("td");
            const updateCaretakerButton = document.createElement("button");
            updateCaretakerButton.type="button";
            updateCaretakerButton.onclick = function() {
                const caretakerNameEdit = document.getElementById("caretakerNameData" + caretakerName.name).innerHTML;
                const caretakerEmailEdit = document.getElementById("caretakerEmailInput" + caretakerName.name).value;
                const caretakerPayEdit = document.getElementById("caretakerPayInput" + caretakerName.name).value;
                const caretakerColorEdit = document.getElementById("caretakerColorInput" + caretakerName.name).value;
                const caretakerIDEdit = props.caretakerList.filter(e => e.name === caretakerNameEdit)[0].docID;
                props.firestore.collection("caretakers").doc(caretakerIDEdit).update(
                    {email: caretakerEmailEdit, 
                    pay: caretakerPayEdit, 
                    color: caretakerColorEdit,
                    }
                );
                props.firestore.collection("shifts").where('caretaker', '==', caretakerName.name).get().then(query => {
                    query.forEach((doc) => {
                        doc.ref.update({
                            caretakerEmail: caretakerEmailEdit,
                            color: caretakerColorEdit
                        })
                    })
                });
                props.firestore.collection("users").where('name', '==', caretakerName.name).get().then(query => {
                    query.forEach((doc) => {
                        doc.ref.update({
                            email: caretakerEmailEdit
                        })
                    })
                });
                
                props.InitialLoadCaretakers();
                props.InitialLoadShifts();
                alert("Caretaker information updated successfully");
            };
            //updateCaretakerButton.buttonSize = "btn--medium";
            //updateCaretakerButton.buttonStyle = "btn--success--solid"
            updateCaretakerButton.innerHTML = "Update";
            updateCaretakerButtonTD.id = "updateCaretakerButtonTD";
            updateCaretakerButton.className = "updateCaretakerButton";
            updateCaretakerButtonTD.appendChild(updateCaretakerButton);


            const deleteCaretakerButtonTD = document.createElement("td");
            
            const deleteCaretakerButton = document.createElement("button");
            deleteCaretakerButton.type="button";
            deleteCaretakerButton.onclick = function() {

                console.log("userList");
                console.log(props.userList);

                const caretakerNameDelete = document.getElementById("caretakerNameData" + caretakerName.name).innerHTML;
                const caretakerToDelete = props.caretakerMasterList.filter(e => e.name === caretakerNameDelete)[0];
                const caretakerToDeleteID = caretakerToDelete.docID;
                const caretakerToDeleteName = caretakerToDelete.name;

                const userToDelete = props.userList.filter(e => e.name === caretakerNameDelete)[0];
                console.log("user to delete");
                console.log(userToDelete);
                const userToDeleteID = userToDelete.docID;

                props.setUserToDeleteID(userToDeleteID);
                props.setCaretakerToDeleteID(caretakerToDeleteID);
                props.setCaretakerToDeleteName(caretakerToDeleteName);
                props.setDisplayDeleteCaretakerDialog(true);
            }
            deleteCaretakerButton.innerHTML = "Delete / Deactivate";
            deleteCaretakerButtonTD.id = "deleteCaretakerButtonTD";
            deleteCaretakerButton.id = "deleteCaretakerButton";
            deleteCaretakerButtonTD.appendChild(deleteCaretakerButton);

            caretakerEditBoxRow.appendChild(caretakerNameData);
            caretakerEditBoxRow.appendChild(caretakerEmailData);
            caretakerEditBoxRow.appendChild(caretakerPayData);
            caretakerEditBoxRow.appendChild(caretakerColorData);
            caretakerEditBoxRow.appendChild(updateCaretakerButtonTD);
            caretakerEditBoxRow.appendChild(deleteCaretakerButtonTD);
            caretakerEditBoxTable.appendChild(caretakerEditBoxRow);

        }

    } 

    const showDeactivatedCaretakers = () => {

        let deactivatedCaretakersTable =  document.getElementById("deactivatedCaretakersTable");


        // Deactivated clients are already being shown on click, so close them
        if (showingDeactivated) {
            setShowingDeactivated(false);
            console.log("not showing");

            // reset deactivated clients div size to default 100
            document.getElementById("deactivatedCaretakersTable").style.height = "0vh";

            deactivatedCaretakersTable.innerHTML = "";

            
            if (props.caretakerList.length <= 12) {
                document.getElementById("editCaretakersDiv").style.height = "100vh";
            } else {
                let increaseDifferenceCaretakersNumber = props.caretakerList.length - 12;
                let increaseVH = (increaseDifferenceCaretakersNumber * 7) + 100;
                let increaseVHString = increaseVH + "vh";
                console.log(increaseVHString);
                document.getElementById("editCaretakersDiv").style.height = increaseVHString;     
            }

        // Deactivated Caretakers are NOT being shown, so show them
        } else {
            setShowingDeactivated(true);
            console.log("showing!");

            deactivatedCaretakersTable.innerHTML = "";
            
            // get total number of Caretakers
            let allCaretakersLength = props.caretakerList.length + props.deactivatedCaretakersList.length;

           

            

            deactivatedCaretakersTable.style.height = "25vh";

            if (allCaretakersLength <= 12) {
                document.getElementById("editCaretakersDiv").style.height = "100vh";
            } else {
                let increaseDifferenceCaretakersNumber = allCaretakersLength - 12;
                let increaseVH = (increaseDifferenceCaretakersNumber * 9) + 110;
                let increaseVHString = increaseVH + "vh";
                console.log(increaseVHString);
                document.getElementById("editCaretakersDiv").style.height = increaseVHString;

                
                deactivatedCaretakersTable.innerHTML = "";

                console.log(props.deactivatedCaretakersList);


                let deactivCaretakersTableLablesTR = document.createElement("tr");

                let deactivCaretakersTableLabelName = document.createElement("td");
                deactivCaretakersTableLabelName.innerHTML = "Name";
                deactivCaretakersTableLablesTR.appendChild(deactivCaretakersTableLabelName);

                let deactivCaretakersTableLabelEmail = document.createElement("td");
                deactivCaretakersTableLabelEmail.innerHTML = "Email";
                deactivCaretakersTableLablesTR.appendChild(deactivCaretakersTableLabelEmail);

                let deactivCaretakersTableLabelRate = document.createElement("td");
                deactivCaretakersTableLabelRate.innerHTML = "Pay";
                deactivCaretakersTableLablesTR.appendChild(deactivCaretakersTableLabelRate);

                let deactivCaretakersTableLabelDeactivationDate = document.createElement("td");
                deactivCaretakersTableLabelDeactivationDate.innerHTML = "Set to 'Deactivated' on:";
                deactivCaretakersTableLablesTR.appendChild(deactivCaretakersTableLabelDeactivationDate);



                deactivatedCaretakersTable.appendChild(deactivCaretakersTableLablesTR);

                for (let deactiv of props.deactivatedCaretakersList) {
                    console.log(deactiv);
                    let deactivCaretakerTR = document.createElement("tr");
                    deactivCaretakerTR.className = "deactivCaretakerTR";

                    let deactivCaretakerNameTD = document.createElement("td");
                    deactivCaretakerNameTD.id = "deactivName" + deactiv.name;
                    deactivCaretakerNameTD.innerHTML = deactiv.name;
                    deactivCaretakerNameTD.className = "deactivTD";
                    deactivCaretakerTR.appendChild(deactivCaretakerNameTD);

                    let deactivCaretakerEmailTD = document.createElement("td");
                    deactivCaretakerEmailTD.id = "deactivEmail" + deactiv.name;
                    deactivCaretakerEmailTD.innerHTML = deactiv.email;
                    deactivCaretakerEmailTD.className = "deactivTD";
                    deactivCaretakerTR.appendChild(deactivCaretakerEmailTD);

                    let deactivCaretakerPayTD = document.createElement("td");
                    deactivCaretakerPayTD.innerHTML = deactiv.pay[0];
                    deactivCaretakerPayTD.className = "deactivTD";
                    deactivCaretakerTR.appendChild(deactivCaretakerPayTD);

                    let deactivCaretakerTimeTD = document.createElement("td");

                    let deactivationDate = new Date(deactiv.deactivationTime);
                    let deactivDayOfMonth = deactivationDate.getDate();
                    let deactivMonth = deactivationDate.getMonth() + 1;
                    let deactivYear = deactivationDate.getFullYear();
                    let deactivDateString = deactivMonth + "/" + deactivDayOfMonth + "/" + deactivYear;

                    deactivCaretakerTimeTD.innerHTML = deactivDateString;
                    deactivCaretakerTimeTD.className = "deactivTimeTD";
                    deactivCaretakerTR.appendChild(deactivCaretakerTimeTD);

                    let deactivCaretakerReactivateTD = document.createElement("td");
                    let deactivCaretakerReactivateButton = document.createElement("button");
                    deactivCaretakerReactivateButton.type = "button";
                    deactivCaretakerReactivateButton.innerHTML = "Reactivate";
                    deactivCaretakerReactivateButton.className = "updateCaretakerButton";
                    deactivCaretakerReactivateButton.id = "reactivate-" + deactiv.name;
                    deactivCaretakerReactivateButton.onclick = function() {
                        props.restoreCaretaker(deactiv);
                    }

                    deactivCaretakerReactivateTD.appendChild(deactivCaretakerReactivateButton);
                    deactivCaretakerTR.appendChild(deactivCaretakerReactivateTD);
                    
                    let deactivCaretakerDeleteTD = document.createElement("td");
                    let deactivCaretakerDeleteButton = document.createElement("button");
                    deactivCaretakerDeleteButton.type = "button";
                    deactivCaretakerDeleteButton.innerHTML = "Delete";
                    deactivCaretakerDeleteButton.id = "deactiv-delete-" + deactiv.name;
                    deactivCaretakerDeleteButton.className = "deleteClientButton";
                    deactivCaretakerDeleteButton.onclick = function() {
                        console.log("delete clicked for " + deactiv.name);

                        const caretakerNameDelete = deactiv.name;
                        const caretakerToDelete = props.deactivatedCaretakersList.filter(e => e.name === caretakerNameDelete)[0];
                        const caretakerToDeleteID = caretakerToDelete.docID;
                        const caretakerToDeleteName = caretakerToDelete.name;
                        props.setCaretakerToDeleteID(caretakerToDeleteID);
                        props.setCaretakerToDeleteName(caretakerToDeleteName);
                        props.setDisplayDeleteCaretakerDialog(true);
                    }


                    deactivCaretakerDeleteTD.appendChild(deactivCaretakerDeleteButton);
                    deactivCaretakerTR.appendChild(deactivCaretakerDeleteTD);

                    deactivatedCaretakersTable.appendChild(deactivCaretakerTR);

                    // test
                    
                }



            }
        }
        
        
        
    }


    return (

        <div className="editCaretakersDiv" id="editCaretakersDiv">
            <a name='editCaretakersTarget'></a>
            <h2>Edit Caretakers</h2>

            <Button onClick={() => showDeactivatedCaretakers()}
            type="button"
            buttonStyle="btn--success--solid"
            buttonSize="btn--medium"
            >
              Show Deactivated Caretakers
            </Button>

            <table id="deactivatedCaretakersTable"></table>
            <div className="formSep"></div>
            <table id="caretakerEditBoxTable"></table>
            
            
                
        </div>


    )
}