import React, { useEffect, useState } from 'react';
import './appointmentNotes.css';
import { Button } from './Button';

export const AppointmentNotes = (props) => {

    const [isEditing, setIsEditing] = useState(false);
    const [textValue, setTextValue] = useState(props.aptClickedObject.event && props.aptClickedObject.event.extendedProps.details);


    useEffect(() => {
        setTextValue(props.aptClickedObject.event && props.aptClickedObject.event.extendedProps.details);
    }, [])

    const handleEditNotes = () => {
        setIsEditing(!isEditing);
      }


    async function handleSaveChanges() {
        let currAptID = props.aptClickedObject.event.extendedProps.aptID;
        await props.editAptNotes(currAptID, textValue);
       // setTextValue(props.aptClickedObject.event && props.aptClickedObject.event.extendedProps.details)  
        setIsEditing(false);
      }
    
      const handleChange = (event) => {
        console.log(event.target.value)
        setTextValue(event.target.value)
      }


    return (
        <div key={props.aptClickedObject.event && props.aptClickedObject.event.extendedProps.details} className='appointmentNotesDiv'>
            
            {!isEditing &&
             <div> 
              <label htmlFor="aptDetailsDiv" id="aptNotesDivLabel">
                  Notes
              </label>
              <div id="aptNotesDiv" >
              {textValue}
              </div>
              <br />
                <br />
                <Button onClick={handleEditNotes} color="primary">
                    Edit Notes
                </Button>
            </div>
            }

            {
              isEditing &&
              <div>
                <div id="aptNotesDivEdit" >
                  <textarea id="aptNotesEdit" value={textValue == null ? props.aptClickedObject.event && props.aptClickedObject.event.extendedProps.details : textValue} onChange={handleChange} ></textarea>
                </div>
                <button id="aptNotesEditButton" onClick={handleSaveChanges}>Save Changes</button>
                
              </div>
            }

        


        </div>
    )
}