import { tsDeclareFunction } from '@babel/types';
import React, { useEffect } from 'react'
import './caretakerFilter3.css';





export const CaretakerFilter3 = (props) => {

    useEffect(() => {

        const filterTable = document.getElementById("caretakerFilterButtonsTable");

        filterTable.innerHTML = "";

        const applyRow = document.createElement("tr");

        const applyButton = document.createElement("button");
        applyButton.textContent = "Apply Filter";
        applyButton.id = "applyButton";
        applyButton.onclick = function() {
            console.log("Apply Filter Clicked");
            console.log("filter buttons array");
            filterButtonsArray.map(e => console.log(e));
            console.log("filter buttons array client");
            filterButtonsArrayClient.map(e => console.log(e));

            const filteredNamesArray = filterButtonsArray.filter(e => e.filterValue == "checked");
            const filteredNamesArrayClient = filterButtonsArrayClient.filter(e => e.filterValue == "checked");

            props.setFilterButtons(filteredNamesArray);
            props.setFilterButtonsClient(filteredNamesArrayClient);

            console.log("filtered names array");
            console.log(filteredNamesArray);

            const filteredNamesOnly = [];
            const filteredNamesOnlyClients = [];
            
            filteredNamesArray.filter(e => e.filterValue == "checked").map(f => filteredNamesOnly.push(f.name));
            filteredNamesArrayClient.filter(e => e.filterValue == "checked").map(f => filteredNamesOnlyClients.push(f.name));

            console.log("filtered names only");
            console.log(filteredNamesOnly);

            console.log("filtered names only clients")
            console.log(filteredNamesOnlyClients)
            console.log("master event list");
            

            let foundShifts = [];

            for (let i = 0; i < props.masterEventList.length; i++) {
                console.log(props.masterEventList.length)
                if (filteredNamesOnly.includes(props.masterEventList[i].caretaker) && filteredNamesOnlyClients.includes(props.masterEventList[i].client)) {
                    console.log("found a shift!")
                    foundShifts.push(props.masterEventList[i]);
                }
            }
            console.log("found shifts here!")
            console.log(foundShifts);
            props.setEventList(foundShifts);
        }

        const applyButtonTD = document.createElement("td");
        applyButtonTD.appendChild(applyButton);

        applyRow.appendChild(applyButtonTD);

        const checkUncheckRowCaretaker = document.createElement("tr");
        checkUncheckRowCaretaker.id = "checkUncheckCaretaker";
        
        const checkAllButtonCT = document.createElement("button");
        checkAllButtonCT.textContent = "Check All Caretakers";
        checkAllButtonCT.id = "checkAllButtonCT";
        checkAllButtonCT.onclick = function() {
            const filterButtons = document.getElementsByClassName("filterButton");

            for (let i = 0; i < filterButtons.length; i++) {

                filterButtons[i].style.backgroundColor = "#14d314";
                filterButtons[i].value= "checked";
                filterButtonsArray.map(e => {if (e.name == filterButtons[i].textContent) {e.filterValue = "checked"}});
                
            }

        }

        const uncheckAllButtonCT = document.createElement("button");
        uncheckAllButtonCT.textContent = "Uncheck All Caretakers";
        uncheckAllButtonCT.id = "uncheckAllButtonCT";
        uncheckAllButtonCT.onclick = function() {
            const filterButtons = document.getElementsByClassName("filterButton");

            for (let i = 0; i < filterButtons.length; i++) {

                filterButtons[i].style.backgroundColor = "#f14c1aad";
                filterButtons[i].value= "unchecked";
                filterButtonsArray.map(e => {if (e.name == filterButtons[i].textContent) {e.filterValue = "unchecked"}});

            }

        }

        const filterButtonsArray = [];

        const checkAllButtonCTTD = document.createElement("td");
        checkAllButtonCTTD.appendChild(checkAllButtonCT);

        const uncheckAllButtonCTTD = document.createElement("td");
        uncheckAllButtonCTTD.appendChild(uncheckAllButtonCT);

        checkUncheckRowCaretaker.appendChild(checkAllButtonCTTD);
        checkUncheckRowCaretaker.appendChild(uncheckAllButtonCTTD);

        
        filterTable.appendChild(applyRow);
        filterTable.appendChild(checkUncheckRowCaretaker);
        //filterTable.appendChild(uncheckAllButtonCTTD);
        //filterTable.appendChild(applyButtonTD);
        

        let filterButtonsRowCT;

        let currStartDateObj = new Date(props.currentStartDate);

        let caretakersPresentInWeek = props.caretakerMasterList.filter(e =>
            e.isDeactivated && e.deactivationTime > currStartDateObj.getTime() ||
            !e.isDeactivated
        )


        for (let i = 0; i < caretakersPresentInWeek.length; i++) {

            if (i % 8 == 0) {
                filterButtonsRowCT = document.createElement("tr");
                filterButtonsRowCT.className = "filterButtonsRowCT";
            }

            const currentCaretakerName = caretakersPresentInWeek[i].name;

            const currentFilterButton = document.createElement("button");
            currentFilterButton.textContent = currentCaretakerName;
            currentFilterButton.id = "filterButton" + currentCaretakerName;
            currentFilterButton.className = "filterButton";
            currentFilterButton.value = "checked";

            currentFilterButton.onclick = function() {
                if (currentFilterButton.value == "checked") {
                    currentFilterButton.style.backgroundColor = "#f14c1aad";
                    currentFilterButton.value = "unchecked";
                    filterButtonsArray.map(e => {if (e.name == currentFilterButton.textContent) {e.filterValue = "unchecked"}})
                } else {
                    currentFilterButton.style.backgroundColor = "#14d314";
                    currentFilterButton.value = "checked";
                    filterButtonsArray.map(e => {if (e.name == currentFilterButton.textContent) {e.filterValue = "checked" }})
                }
            }

            

            filterButtonsArray.push({
                name : currentFilterButton.textContent,
                filterValue : currentFilterButton.value
            });
            

            const currentFilterButtonTD = document.createElement("td");
            currentFilterButtonTD.appendChild(currentFilterButton);


            filterButtonsRowCT.appendChild(currentFilterButtonTD);
            filterButtonsRowCT.className = "filterButtonsRowCT";

            filterTable.appendChild(filterButtonsRowCT);

            props.setFilterButtons(props.caretakerMasterListNames);
            props.setFilterButtonsArray(filterButtonsArray);

        }


        const checkAllButtonClient = document.createElement("button");
        checkAllButtonClient.textContent = "Check All Clients";
        checkAllButtonClient.id = "checkAllButtonClient";
        checkAllButtonClient.onclick = function() {
            const filterButtonsClient = document.getElementsByClassName("filterButtonClient");

            for (let i = 0; i < filterButtonsClient.length; i++) {

                filterButtonsClient[i].style.backgroundColor = "#14d314";
                filterButtonsClient[i].value= "checked";
                filterButtonsArrayClient.map(e => {if (e.name == filterButtonsClient[i].textContent) {e.filterValue = "checked"}});
  
            }
        }

        const uncheckAllButtonClient = document.createElement("button");
        uncheckAllButtonClient.textContent = "Uncheck All Clients";
        uncheckAllButtonClient.id = "uncheckAllButtonClient";
        uncheckAllButtonClient.onclick = function() {
            const filterButtonsClient = document.getElementsByClassName("filterButtonClient");

            for (let i = 0; i < filterButtonsClient.length; i++) {

                filterButtonsClient[i].style.backgroundColor = "#f14c1aad";
                filterButtonsClient[i].value= "unchecked";
                filterButtonsArrayClient.map(e => {if (e.name == filterButtonsClient[i].textContent) {e.filterValue = "unchecked"}});
            }
        }


        const checkAllButtonClientTD = document.createElement("td");
        checkAllButtonClientTD.appendChild(checkAllButtonClient);

        const uncheckAllButtonClientTD = document.createElement("td");
        uncheckAllButtonClientTD.appendChild(uncheckAllButtonClient);

        
        const checkUncheckRowClient = document.createElement("tr");
        checkUncheckRowClient.id = "checkUncheckClient";
        checkUncheckRowClient.appendChild(checkAllButtonClientTD);
        checkUncheckRowClient.appendChild(uncheckAllButtonClientTD);

        //checkUncheckRowClient.appendChild(uncheckAllButtonClientTD);

        filterTable.appendChild(checkUncheckRowClient);



        const filterButtonsArrayClient = [];

        let filterButtonsRowCL = document.createElement("tr");

        let clientsPresentInWeek = props.clientMasterList.filter(e =>
            e.isDeactivated && e.deactivationTime > currStartDateObj.getTime() ||
            !e.isDeactivated
        )

        for (let i = 0; i < clientsPresentInWeek.length; i++) {

            if (i % 8 == 0) {
                filterButtonsRowCL = document.createElement("tr");
                filterButtonsRowCL.className = "filterButtonsRowCL";
            }

            const currentClientName = clientsPresentInWeek[i].name;

            const currentFilterButtonClient = document.createElement("button");
            currentFilterButtonClient.textContent = currentClientName;
            currentFilterButtonClient.id = "filterButton" + currentClientName;
            currentFilterButtonClient.className = "filterButtonClient";
            currentFilterButtonClient.value = "checked";


            currentFilterButtonClient.onclick = function() {
                if (currentFilterButtonClient.value == "checked") {
                    currentFilterButtonClient.style.backgroundColor = "#f14c1aad";
                    currentFilterButtonClient.value = "unchecked";
                    filterButtonsArrayClient.map(e => {if (e.name == currentFilterButtonClient.textContent) {e.filterValue = "unchecked"}})
                } else {
                    currentFilterButtonClient.style.backgroundColor = "#14d314";
                    currentFilterButtonClient.value = "checked";
                    filterButtonsArrayClient.map(e => {if (e.name == currentFilterButtonClient.textContent) {e.filterValue = "checked" }})
                }
            }

            filterButtonsArrayClient.push({
                name : currentFilterButtonClient.textContent,
                filterValue : currentFilterButtonClient.value
            });

            const currentFilterButtonClientTD = document.createElement("td");
            currentFilterButtonClientTD.appendChild(currentFilterButtonClient);


            filterButtonsRowCL.appendChild(currentFilterButtonClientTD);
            filterButtonsRowCL.className = "filterButtonsRowCT";

            filterTable.appendChild(filterButtonsRowCL);

            props.setFilterButtonsClient(props.clientListNames);
            props.setFilterButtonsArrayClient(filterButtonsArrayClient);
            

        }



    }, [props.clientListNames, props.masterEventList]);


    

    return (
        <div className='caretakerFilterDiv'>
            <label id="filterLabel">Filter</label>
            <table id="caretakerFilterButtonsTable"></table>
        </div>
    )
}
