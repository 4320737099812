import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './editAppointmentDialog.css';
import { AppointmentNotes } from './AppointmentNotes';

export default function EditAppointmentDialog(props) {
  const [open, setOpen] = useState(false);


  useEffect(() => {
    setOpen(props.displayEditAppointmentDialog);
  })

  const handleClose = () => {
    props.setDisplayEditAppointmentDialog(false);
  };

  const prettifyTime = (time) => {
    let timeAfterSplit = time.split("T")[1];
    let hourVal = timeAfterSplit.split(":")[0];
    console.log("TIME AFTER SPLIT");
    console.log(timeAfterSplit);
    let minVal = timeAfterSplit.split(":")[1];
    console.log(minVal);
    let isPM = false;
    if (hourVal >= 13) {
      hourVal -= 12;
      isPM = true;
    }
    if (hourVal == 12) {
      isPM = true;
    }
    return isPM ? `${hourVal}:${minVal} PM` : `${hourVal}:${minVal} AM`;
  }

  async function handleDelete() {
    console.log("apt clicked");
    let aptToDeleteID = props.aptClickedObject.event.extendedProps.aptID;
    await props.setAptToDeleteID(aptToDeleteID);
    props.setDisplayConfirmDeleteAppointmentDialog(true);
    handleClose();
  }


  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Appointment Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div id="aptTimeAndDateDiv">
                {props.aptClickedObject.event && new Date(props.aptClickedObject.event.startStr).toDateString()} 
                <br /> 
                {props.aptClickedObject.event && prettifyTime(new Date(new Date(props.aptClickedObject.event.startStr).toString().split('GMT')[0]+' UTC').toISOString())} 
                
                
            </div>
            <br />
            <br />
            
            <AppointmentNotes aptClickedObject={props.aptClickedObject} editAptNotes={props.editAptNotes}/>


            <br />
            <br />
            
          </DialogContentText>
         

        </DialogContent>

        <DialogActions>

          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        
        </DialogActions>
      </Dialog>
    </div>
  );
}