import React from 'react'
import { Button } from './Button'
import './signIn.css'
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';


export const SignIn = (props) => {

    return (
        <div className="SignInDiv">
            <section id="whenSignedOut">
                <div id="welcomeDiv">
                    <h1>Welcome</h1>
                    <h3>Please sign in.</h3>
                </div>
                <div id='googleButtonDiv'>
                <Button onClick={props.signInWithGoogle}
                type="button"
                buttonStyle="btn--primary--solid"
                buttonSize="btn--large"
                >
                Sign in with Google
                </Button>

                </div>

            
            </section>
        </div>
    )
}


