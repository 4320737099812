
import React, { useState, useEffect } from 'react'
import './App.css';
import MyCalendar from './MyCalendar';
import { Button } from './components/Button';
import { AddShiftBox } from './components/AddShiftBox';
import { Nav } from './components/Nav';
import { AddClientBox } from './components/AddClientBox';
import { AddCaretakerBox } from './components/AddCaretakerBox';
import { AddAppointmentBox } from './components/AddAppointmentBox';

import { SignIn } from './components/SignIn';
import { SignOut } from './components/SignOut';
import  Home  from './components/Home';



//import firebase from 'firebase/app';
//import 'firebase/firestore';
//import 'firebase/auth';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { getClippingParents, refineEventDef } from '@fullcalendar/react';
import { HoursBox } from './components/HoursBox';
import { render } from 'preact/compat';


// AIzaSyDBMJ0rld7uB834d6OBDryTFljXyk6xsxQ

// OG API code: 


firebase.initializeApp({
  apiKey: "AIzaSyDBMJ0rld7uB834d6OBDryTFljXyk6xsxQ",
  authDomain: "bright-feat-320902.firebaseapp.com",
  projectId: "bright-feat-320902",
  storageBucket: "bright-feat-320902.appspot.com",
  messagingSenderId: "97444724590",
  appId: "1:97444724590:web:c798ec15bd47c6e6918b7f",
  measurementId: "G-WW1Z92L41D"
})

const auth = firebase.auth();
const firestore = firebase.firestore();


function App() {
  
  const [eventList, setEventList] = useState([]);
  const [appointmentList, setAppointmentList] = useState([]);
  const [user] = useAuthState(auth);
  const [clientList, setClientList] = useState([]);
  const [caretakerList, setCaretakerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [currentStartDate, setCurrentStartDate] = useState(0);
  const [currentEndDate, setCurrentEndDate] = useState(0);
  const [currentStartTimestr, setCurrentStartTimestr] = useState("");
  const [currentEndTimestr, setCurrentEndTimestr] = useState("");
  const [displayDialog, setDisplayDialog] = useState(false);
  const [displayDeleteCaretakerDialog, setDisplayDeleteCaretakerDialog] = useState(false);
  const [displayDeleteClientDialog, setDisplayDeleteClientDialog] = useState(false);
  const [displayEditAppointmentDialog, setDisplayEditAppointmentDialog] = useState(false);
  const [eventClickedObject, setEventClickedObject] = useState({});
  const [caretakerToDeleteID, setCaretakerToDeleteID] = useState("");
  const [caretakerToDeleteName, setCaretakerToDeleteName] = useState("");
  const [clientToDeleteID, setClientToDeleteID] = useState("");
  const [clientToDeleteName, setClientToDeleteName] = useState("");
  const [userToDeleteID, setUserToDeleteID] = useState("");
  const [renderRepeatOptions, setRenderRepeatOptions] = useState(false);
  const [caretakerListNames, setCaretakerListNames] = useState([]);
  const [clientListNames, setClientListNames] = useState([]);
  const [filterButtons, setFilterButtons] = useState([]);
  const [filteredEventList, setFilteredEventList] = useState([]);
  const [masterEventList, setMasterEventList] = useState([]);
  const [filterButtonsArray, setFilterButtonsArray] = useState([]);
  const [caretakerMasterList, setCaretakerMasterList] = useState([]);
  const [caretakerMasterListNames, setCaretakerMasterListNames] = useState([]);
  const [aptClickedObject, setAptClickedObject] = useState({});
  const [aptToDeleteID, setAptToDeleteID] = useState("");
  const [displayConfirmDeleteAppointmentDialog, setDisplayConfirmDeleteAppointmentDialog] = useState(false);
  const [renderFlatFeeOptions, setRenderFlatFeeOptions] = useState(false);
  const [currentStartDateString, setCurrentStartDateString] = useState("");
  const [currentEndDateString, setCurrentEndDateString] = useState("");
  const [weeksLoaded, setWeeksLoaded] = useState([]);
  const [renderOvernightOptions, setRenderOvernightOptions] = useState(false);
  const [filterButtonsClient, setFilterButtonsClient] = useState([]);
  const [filterButtonsArrayClient, setFilterButtonsArrayClient] = useState([]);
  const [currWeekStartTimeSecs, setCurrWeekStartTimeSecs] = useState(0);
  const [currWeekEndTimeSecs, setCurrWeekEndTimeSecs] = useState(0);
  const [clientMasterList, setClientMasterList] = useState([]);
  const [clientMasterListNames, setClientMasterListNames] = useState([]);
  const [displayDeactivatedClientsDialog, setDisplayDeactivatedClientsDialog]= useState(false);
  const [deactivatedClientsList, setDeactivatedClientsList] = useState([]);
  const [deactivatedCaretakersList, setDeactivatedCaretakersList] = useState([]);
  
  useEffect(() => {
    console.log("Use Effect")
    InitialLoadUsers();
  }, []);


   /*
   *** Checks whether user logged in is admin or caretaker
  */
  const checkAdminPriviledge = () => {
    if (user.email && user.email === "paulrichdev@gmail.com" || user.email === "ccslovingcarellc@gmail.com") {
     // console.log("ADMIN CHECK - PASSED ! ! !");
      return true;
    } else {
    //  console.log("ADMIN CHECK - FAILED ! ! !");
      return false;
    }
  }

   /*
   *** Load the users from the DB. Needed to login.
  */
  const InitialLoadUsers = () => {
    setLoading(true);
    let users = [];
    firestore.collection("users").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        
        let userRecord = {
          name: doc.data().name,
          email: doc.data().email,
          type: doc.data().type,
          docID: doc.id,
          isDeactivated: doc.data().isDeactivated
        }
        users.push(userRecord);
      });
      setUserList(users);
      setLoading(false);
    });
  }



   /*
   *** Load shifts to the calendar. Not sure this is still needed. Remove soon.
  */


   // limited
   // firestore.collection("shifts").where("startTimeSecs", ">", currWeekStartTimeSecs).where("startTimeSecs", "<", currWeekEndTimeSecs).limit(150).get().then((querySnapshot)
 async function InitialLoadShifts() {
  console.log("INITIAL LOAD SHIFTS - HOME COMPONENT");
  let counterForLog = 0;
  let shifts = [];
  let masterShifts = [];

  if (user.email === "paulrichdev@gmail.com" || user.email === "ccslovingcarellc@gmail.com") {
      await firestore.collection("shifts").get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
         // console.log("loading shift number: ");
        //  console.log(counterForLog);   
          counterForLog++;
          let shiftRecord = {
           client: doc.data().client,
           caretaker: doc.data().caretaker,
           start: doc.data().startTime,
           end: doc.data().endTime,
           title: doc.data().client + " - " + doc.data().caretaker,
           comments: doc.data().comments,
           color: doc.data().color,
           editedStartTime: doc.data().editedStartTime,
           editedEndTime: doc.data().editedEndTime,
           caretakerEmail: doc.data().caretakerEmail,
           docID: doc.id,
           repeatingShift: doc.data().repeatingShift,
           repeatingShiftID: doc.data().repeatingShiftID,
           startTimeSecs: doc.data().startTimeSecs,
           originalStartTime: doc.data().originalStartTime,
           originalEndTime: doc.data().originalEndTime,
           isFlatFee: doc.data().isFlatFee,
           flatFeeAmount: doc.data().flatFeeAmount,
           flatFeeAmountClient: doc.data().flatFeeAmountClient,
           shiftMileage: doc.data().shiftMileage
          }
      
          masterShifts.push(shiftRecord);
          shifts.push(shiftRecord);

      });
      setEventList(shifts);
      setMasterEventList(masterShifts);
      console.log("shifts in Initial Load - App");
      console.log(shifts);
      console.log("master shifts in Initial Load app")
      console.log(masterShifts);
      }); 

  } else {

      firestore.collection("shifts").where("caretakerEmail", "==", user.email).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
        // console.log("loading shift number: ");
        //  console.log(counterForLog);   
          counterForLog++;
          let shiftRecord = {
           client: doc.data().client,
           caretaker: doc.data().caretaker,
           start: doc.data().startTime,
           end: doc.data().endTime,
           comments: doc.data().comments,
           color: doc.data().color,
           editedStartTime: doc.data().editedStartTime,
           editedEndTime: doc.data().editedEndTime,
           caretakerEmail: doc.data().caretakerEmail,
           docID: doc.id,
           repeatingShift: doc.data().repeatingShift,
           repeatingShiftID: doc.data().repeatingShiftID,
           startTimeSecs: doc.data().startTimeSecs,
           originalStartTime: doc.data().originalStartTime,
           originalEndTime: doc.data().originalEndTime,
           isFlatFee: doc.data().isFlatFee,
           flatFeeAmount: doc.data().flatFeeAmount,
           flatFeeAmountClient: doc.data().flatFeeAmountClient,
           shiftMileage: doc.data().shiftMileage
          }
           
      shifts.push(shiftRecord);
      
      });
      setMasterEventList([...shifts]);
      setEventList([...shifts]);
      
      }); 


  }

      
  
}
            

 /*
   *** Use firebase's auth service to log users in
  */
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider).then((result) => {
      var credential = result.credential;
      // The signed-in user info.
      var user = result.user;
      console.log("USER: " + user.email);
    }).catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      var email = error.email;
      var credential = error.credential;
    })
  }

 /*
   *** Add shift to the main calendar. Includes options for repeating shifts, flat (or special) fees.
  */
  async function addShift(shiftsToAdd) {
    
    let batch = firestore.batch();


    for (let shift of shiftsToAdd) {

      console.log("shift Adding");
      console.log(shift)

      // Get caretaker record from list
      let caretakerInUserList = userList.filter(e => e.name === shift.chosenCaretaker)[0];

      // Calculate the mileage owed, if any to the list
      shift.shiftMileage = shift.shiftMileage === undefined || shift.shiftMileage === "" ? 0 : shift.shiftMileage;

      // If repeating shift, add shiftRepeatID with rest of shift information
      if (shift.shiftRepeat === true) {
        batch.set(firestore.collection("shifts").doc(), {
          client: shift.chosenClient,
          caretaker: shift.chosenCaretaker,
          startTime: shift.startDatetime,
          endTime: shift.endDatetime,
          comments: shift.shiftComments,
          color: shift.caretakerColor,
          editedStartTime: "",
          editedEndTime: "",
          prettyEditedStartTime: "",
          prettyEditedEndTime: "",
          caretakerEmail: caretakerInUserList.email,
          repeatingShift: true,
          repeatingShiftID: shift.shiftRepeatID,
          startTimeSecs: shift.startTimeSecs,
          isFlatFee: shift.isFlatFeeShift,
          flatFeeAmount: shift.flatFeeCaretaker,
          flatFeeAmountClient: shift.flatFeeClient,
          shiftMileage: shift.shiftMileage
        });
      } else {
      // Shift is not a repeating shift, omit the repeatingShiftID
      batch.set(firestore.collection("shifts").doc(), {
          client: shift.chosenClient,
          caretaker: shift.chosenCaretaker,
          startTime: shift.startDatetime,
          endTime: shift.endDatetime,
          comments: shift.shiftComments,
          color: shift.caretakerColor,
          editedStartTime: "",
          editedEndTime: "",
          prettyEditedStartTime: "",
          prettyEditedEndTime: "",
          caretakerEmail: caretakerInUserList.email,
          repeatingShift: false,
          repeatingShiftID: null,
          startTimeSecs: shift.startTimeSecs,
          isFlatFee: shift.isFlatFeeShift,
          flatFeeAmount: shift.flatFeeCaretaker,
          flatFeeAmountClient: shift.flatFeeClient,
          shiftMileage: shift.shiftMileage
        });
      }



    }

    batch.commit().then(() => {
      let shifts = [];
      firestore.collection("shifts").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          
          let shiftRecord = {
            client: doc.data().client,
            caretaker: doc.data().caretaker,
            start: doc.data().startTime,
            end: doc.data().endTime,
            comments: doc.data().comments,
            color: doc.data().color,
            editedStartTime: doc.data().editedStartTime,
            editedEndTime: doc.data().editedEndTime,
            prettyEditedStartTime: doc.data().prettyEditedStartTime,
            prettyEditedEndTime: doc.data().prettyEditedEndTime,
            caretakerEmail: doc.data().caretakerEmail,
            docID: doc.id,
            repeatingShift: doc.data().repeatingShift,
            repeatingShiftID: doc.data().repeatingShiftID,
            startTimeSecs: doc.data().startTimeSecs,
            isFlatFee: doc.data().isFlatFee,
            flatFeeAmount: doc.data().flatFeeAmount,
            flatFeeAmountClient: doc.data().flatFeeAmountClient,
            shiftMileage: Number(doc.data().shiftMileage)
          }
  
          shifts.push(shiftRecord);
  
        });
  
       // setEventList([...shifts]);
       // setMasterEventList([...shifts]);
  
      });
    })

    // clientName, caretakerName, startSecs, startTimeAndDate, endTimeAndDate, comments, color, shiftRepeat, srID, isFlatFeeShift, flatFeeShiftAmountCaretaker, shiftMileage, flatFeeShiftAmountClient

    
    // Get shifts from the DB
  
    
    InitialLoadShifts();
    applyFilterToMasterList();

  }


   /*
   *** Add a caretaker to the caretaker table. Also adds caretaker as a user to user table
       by calling addUser below.
  */
  const addCaretaker = (caretakerName, caretakerEmail, caretakerPay, caretakerColor) => {
    firestore.collection('caretakers').add({
      clients: [],
      name: caretakerName,
      email: caretakerEmail,
      pay: {0 : caretakerPay},
      color: caretakerColor
    })
   
    let masterCaretakers = [];
    let masterCaretakersNames = [];
    let caretakers = [];
    let caretakersNames = [];
    firestore.collection("caretakers").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        
        let caretakerRecord = {
          name: doc.data().name,
          email: doc.data().email,
          pay: doc.data().pay,
          color: doc.data().color,
          docID: doc.id,
          isDeactivated: doc.data().isDeactivated,
          deactivationTime: doc.data().deactivationTime
        }

        let caretakerNameForList = doc.data().name;

        if (!caretakerRecord.isDeactivated) {
          caretakersNames.push(caretakerNameForList);
          caretakers.push(caretakerRecord);
        }
        masterCaretakersNames.push(caretakerNameForList);
        masterCaretakers.push(caretakerRecord);
      });
      setCaretakerList(caretakers);
      setCaretakerListNames(caretakersNames);
      setCaretakerMasterList(masterCaretakers);
      setCaretakerMasterListNames(masterCaretakersNames);

    });
  }

   /*
   *** Adds a Client to the client table
  */
  const addClient = (clientName, clientRate) => {
    firestore.collection('clients').add({
      name: clientName,
      rate: {0: clientRate}  // pay: {0 : caretakerPay},
    })
    let clients = [];
    firestore.collection("clients").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        
        let clientRecord = {
          name: doc.data().name,
          rate: doc.data().rate,
          docID: doc.id          
        }
        clients.push(clientRecord);
      });
      setClientList(clients);
    });
  }

   /*
   *** Adds a user to user table, giving access. Called from addCaretaker
  */
  async function addUser(userName, userEmail, userType) {
    await firestore.collection('users').add({
      name: userName,
      email: userEmail,
      type: userType
    });
    
    let users = [];
    await firestore.collection("users").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let userRecord = {
          name: doc.data().name,
          email: doc.data().email,
          type: doc.data().type,
          docID: doc.id
        }
        users.push(userRecord);
      });
      
    })
    setUserList([...users]);
  }

   /*
   *** Get time from datetime object and convert to 12hr std time
  */
  const prettifyTime = (time) => {
    let timeAfterSplit = time.split("T")[1]
    let hourVal = timeAfterSplit.split(":")[0];
    let minVal = timeAfterSplit.split(":")[1];
    let isPM = false;
    if (hourVal >= 13) {
      hourVal -= 12;
      isPM = true;
    }
    if (hourVal >= 12) {
      isPM = true;
    }
    return isPM ? `${hourVal}:${minVal} PM` : `${hourVal}:${minVal} AM`;
  }

 /*
   *** Add appointments (medical, dental, etc.) to appointment calendar.
  */
  const addAppointment = (clientName, startDatetime, endDatetime, commentDetails, addedByUser, appointmentLabel) => {
      firestore.collection("appointments").add({
        client: clientName,
        startTime: startDatetime,
        endTime: endDatetime,
        details: commentDetails,
        addedBy: addedByUser,
        aptLabel: appointmentLabel
      });

      let appointments = [];

      firestore.collection("appointments").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          
          let aptRecord = {
            client: doc.data().client,
            start: doc.data().startTime,
            end: doc.data().endTime,
            details: doc.data().details,
            addedBy: doc.data().addedBy,
            aptLabel: doc.data().aptLabel,
            aptID: doc.id
          }
          appointments.push(aptRecord);
        });
  
        setAppointmentList(appointments);
      });

  }

  /*
   *** Caretaker side shift time editing
  */
  async function editTimeWorked(startStr, editedStartTime, editedEndTime, 
    ogCaretakerForShift, clientForShift, originalStartTime, originalEndTime)  {

    console.log("edit time worked");

    await firestore.collection("shifts").where("caretaker", "==", ogCaretakerForShift).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        
        console.log("doc");
        console.log(doc.data());

        console.log("start Str")
        console.log(startStr);
        console.log("end");

        //const editedObj = [{
        //  editedByName: og
        //}]
        

        if (doc.data().startTime.startsWith(startStr) && ogCaretakerForShift == doc.data().caretaker && clientForShift == doc.data().client) {
          console.log("updating firestore in editShiftTime");
          console.log(editedStartTime);
          firestore.collection("shifts").doc(doc.id).update({editedStartTime: editedStartTime});
          firestore.collection("shifts").doc(doc.id).update({editedEndTime: editedEndTime});
          firestore.collection("shifts").doc(doc.id).update({originalStartTime: originalStartTime});
          firestore.collection("shifts").doc(doc.id).update({originalEndTime: originalEndTime});
          firestore.collection("shifts").doc(doc.id).update({startTime: editedStartTime});
          firestore.collection("shifts").doc(doc.id).update({endTime: editedEndTime});
          //firestore.collection("shifts").doc(doc.id).update({editedBy: firestore.FieldValue.arrayUnion(ogCaretakerForShift) });
        }
      });
      
    });
    let shifts = [];
    await firestore.collection("shifts").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        
        let shiftRecord = {
          client: doc.data().client,
          caretaker: doc.data().caretaker,
          start: doc.data().startTime,
          end: doc.data().endTime,
          comments: doc.data().comments,
          color: doc.data().color,
          editedStartTime: doc.data().editedStartTime,
          editedEndTime: doc.data().editedEndTime,
          caretakerEmail: doc.data().caretakerEmail,
          prettyEditedStartTime: doc.data().prettyEditedStartTime,
          prettyEditedEndTime: doc.data().prettyEditedEndTime,
          docID: doc.id,
          repeatingShift: doc.data().repeatingShift,
          repeatingShiftID: doc.data().repeatingShiftID,
          startTimeSecs: doc.data().startTimeSecs,
          originalStartTime: doc.data().originalStartTime,
          originalEndTime: doc.data().originalEndTime,
          isFlatFee: doc.data().isFlatFee,
          flatFeeAmount: doc.data().flatFeeAmount,
          flatFeeAmountClient: doc.data().flatFeeAmountClient,
          shiftMileage: doc.data().shiftMileage
        }

        if (user.email === "paulrichdev@gmail.com" || user.email === "ccslovingcarellc@gmail.com" || user.email === shiftRecord.caretakerEmail) {
          shifts.push(shiftRecord);
         }
      });

      console.log("editing time");
      console.log(shifts);
      setEventList([...shifts]);
      setMasterEventList([...shifts]);    
    });
    InitialLoadShifts();
  }

  async function editInfoForShift(startStr, endStr, ogCaretakerForShift, updatedCaretakerForShift, ogClientForShift, updatedClientForShift, adjustedStartTime, adjustedEndTime, updatedFlatFeeAmount, updatedMileage, updatedFlatFeeAmountClient) {

    const updatedCaretakerObj = caretakerList.filter(e => e.name == updatedCaretakerForShift)[0];
    const updatedCaretakerEmail = updatedCaretakerObj.email;



    // firestore.collection("shifts").where("startTimeSecs", ">", currWeekStartTimeSecs).where("startTimeSecs", "<", currWeekEndTimeSecs).limit(150).get().then((querySnapshot)

    // firestore.collection("shifts").limit(100).where("caretaker", "==", ogCaretakerForShift).where("client", "==", ogClientForShift).get().then((querySnapshot)

    await firestore.collection("shifts").get().then((querySnapshot) => {
      let shiftLoaderCount = 0;
      querySnapshot.forEach((doc) => {
        console.log("Loading shift " + shiftLoaderCount++);
        console.log("doc.data().startTime");
        console.log(doc.data().startTime);
        console.log("starts with...");
        console.log(startStr);
        if (doc.data().startTime.startsWith(startStr) && ogCaretakerForShift == doc.data().caretaker && ogClientForShift == doc.data().client) { 
          console.log("shift updating ---");
          console.log(adjustedStartTime);
          console.log(adjustedEndTime);
          firestore.collection("shifts").doc(doc.id).update({caretaker: updatedCaretakerForShift});
          firestore.collection("shifts").doc(doc.id).update({caretakerEmail: updatedCaretakerObj.email});
          firestore.collection("shifts").doc(doc.id).update({color: updatedCaretakerObj.color});
          firestore.collection("shifts").doc(doc.id).update({client: updatedClientForShift});
          firestore.collection("shifts").doc(doc.id).update({startTime: adjustedStartTime});
          firestore.collection("shifts").doc(doc.id).update({endTime: adjustedEndTime});
          firestore.collection("shifts").doc(doc.id).update({shiftMileage: updatedMileage});
          firestore.collection("shifts").doc(doc.id).update({editedEndTime: ""});
          firestore.collection("shifts").doc(doc.id).update({editedStartTime: ""});

          {updatedFlatFeeAmount && firestore.collection("shifts").doc(doc.id).update({flatFeeAmount: updatedFlatFeeAmount});}
          {updatedFlatFeeAmountClient && firestore.collection("shifts").doc(doc.id).update({flatFeeAmountClient: updatedFlatFeeAmountClient});}
        }
      });
      
    });

    let shifts = [];

    // 

    // firestore.collection("shifts").get().then((querySnapshot) 
    
    await firestore.collection("shifts").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        
        let shiftRecord = {
          client: doc.data().client,
          caretaker: doc.data().caretaker,
          start: doc.data().startTime,
          end: doc.data().endTime,
          comments: doc.data().comments,
          color: doc.data().color,
          editedStartTime: doc.data().editedStartTime,
          editedEndTime: doc.data().editedEndTime,
          caretakerEmail: doc.data().caretakerEmail,
          prettyEditedStartTime: doc.data.prettyEditedStartTime,
          prettyEditedEndTime: doc.data.prettyEditedEndTime,
          docID: doc.id,
          repeatingShift: doc.data().repeatingShift,
          repeatingShiftID: doc.data().repeatingShiftID,
          startTimeSecs: doc.data().startTimeSecs,
          isFlatFee: doc.data().isFlatFee,
          flatFeeAmount: doc.data().flatFeeAmount,
          flatFeeAmountClient: doc.data().flatFeeAmountClient,
          shiftMileage: doc.data().shiftMileage
        }

        if (user.email === "paulrichdev@gmail.com" || user.email === "ccslovingcarellc@gmail.com" || user.email === shiftRecord.caretakerEmail) {
          shifts.push(shiftRecord);
         }
      });
      setEventList([...shifts]);  
      setMasterEventList([...shifts]);  
    });


    InitialLoadShifts();
    applyFilterToMasterList();
    
  }


  const applyFilterToMasterList = () => {

    const filteredNamesOnly = [];

    console.log("filter buttons Array - AFTML");
    console.log(filterButtonsArray);
            
    filterButtonsArray.filter(e => e.filterValue == "checked").map(f => filteredNamesOnly.push(f.name));


    console.log("filteredNames Only");
    console.log(filteredNamesOnly);


    let foundShifts = [];

    for (let i = 0; i < masterEventList.length; i++) {
        if (filteredNamesOnly.includes(masterEventList[i].caretaker)) {
            foundShifts.push(masterEventList[i]);
        }
    }

    console.log("master list - AFTML");
    console.log(masterEventList);

    console.log("found shifts - AFTML");
    console.log(foundShifts);
    
    setEventList(foundShifts);
  }

  async function InitialLoadAppointments() {
    let apts = [];
    firestore.collection("appointments").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            let aptRecord = {
                client: doc.data().client,
                start: doc.data().startTime,
                end: doc.data().endTime,
                details: doc.data().details,
                addedBy: doc.data().addedBy,
                aptLabel: doc.data().aptLabel,
                aptID: doc.id
            }
            apts.push(aptRecord);
        });
        setAppointmentList(apts);
        console.log("appointments load - app");
        console.log(apts);
    })
}

  async function deleteShift(arg) {
    await firestore.collection("shifts").doc(arg.event.extendedProps.docID).delete();
    console.log("deleted shift");
    InitialLoadShifts();
    applyFilterToMasterList();
    
  }

  async function deleteApt() {
    await firestore.collection("appointments").doc(aptToDeleteID).delete();
    setAptToDeleteID("");
    console.log("delete apt");
    InitialLoadAppointments();
  }

  async function editAptNotes(aptID, newDetails) {
    await firestore.collection("appointments").doc(aptID).update({
      details: newDetails
    });
    
    InitialLoadAppointments();
    console.log("updated apt notes")
  }

  async function deleteFutureShifts(arg) {
    const startDateOfClicked = arg.event.start;
    console.log("delete future shifts");
    console.log(startDateOfClicked.getTime());
    await firestore.collection("shifts").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc.data().repeatingShift === true && doc.data().repeatingShiftID === arg.event.extendedProps.repeatingShiftID && doc.data().startTimeSecs >= arg.event.extendedProps.startTimeSecs) {
          console.log("match");
          console.log(doc.data());
          const deleteID = doc.id;
          firestore.collection("shifts").doc(deleteID).delete();
        }
        console.log(arg.event.extendedProps.startTimeSecs);
      })
      
    });
    InitialLoadShifts();
    applyFilterToMasterList();
  }

  async function restoreClient(clientObj) {
    await firestore.collection("clients").doc(clientObj.docID).update({isDeactivated: false, deactivationTime: 0});

    let newDeactivList = deactivatedClientsList.filter(e => e.docID != clientObj.docID);
    let newActivList = [...clientList, clientObj]

    setDeactivatedClientsList(newDeactivList);
    setClientList(newActivList);

    alert("" + clientObj.name + " successfully restored. Click the 'show deactivated clients' button to see changes.");
  }

  async function restoreCaretaker(caretakerObj) {

    console.log("user list!!")
    console.log(userList);

    let caretakerToRestoreUserObj = userList.filter(e => e.email == caretakerObj.email);

    console.log("caretakerObj");
    console.log(caretakerObj);

    console.log("caretaker to restore");
    console.log(caretakerToRestoreUserObj);

    await firestore.collection("caretakers").doc(caretakerObj.docID).update({isDeactivated: false, deactivationTime: 0});
    
    await firestore.collection("users").add({
      email: caretakerObj.email,
      name: caretakerObj.name,
      type: "caretaker"
    });

    let newDeactivList = deactivatedCaretakersList.filter(e => e.docID != caretakerObj.docID);
    let newActivList = [...caretakerList, caretakerObj]

    setDeactivatedCaretakersList(newDeactivList);
    setCaretakerList(newActivList);
    InitialLoadUsers();

    console.log("caretakerobj - restore");
    console.log(caretakerObj);

    alert("" + caretakerObj.name + " successfully restored. Click the 'show deactivated clients' button to see changes.");
  }


  const checkEmail = () => {

    if (userList.some(e => e.email === user.email && e.isDeactivated != true)) {
      return true;
    }
    
    auth.signOut()
    return  <SignIn auth={auth} signInWithGoogle={signInWithGoogle}/>
  }

  const getTimeAndDate = (startDate, endDate, startTimestr, endTimestr) => {
    setLoading(true);
    setCurrentStartDate(startDate);
    setCurrentEndDate(endDate);
    setCurrentStartTimestr(startTimestr);
    setCurrentEndTimestr(endTimestr);
    setLoading(false);
  } 

  if (loading) {
    return <h1>Loading...</h1>
  }

  return (
    <div className="App">
      
      <a name="homeTarget"></a>
      { !auth.currentUser && <SignIn auth={auth} signInWithGoogle={signInWithGoogle}/> }
      
      <SignOut auth={auth} />
      {auth.currentUser && checkEmail() && 
      <Home 
        checkAdminPriviledge={checkAdminPriviledge}
        currentStartDate={currentStartDate}
        currentEndDate={currentEndDate}
        eventList={eventList}
        setEventList={setEventList}
        getTimeAndDate={getTimeAndDate} 
        displayDialog={displayDialog}
        setDisplayDialog={setDisplayDialog} 
        displayDeleteCaretakerDialog={displayDeleteCaretakerDialog} 
        displayDeleteClientDialog={displayDeleteClientDialog}
        setDisplayDeleteCaretakerDialog={setDisplayDeleteCaretakerDialog}
        setDisplayDeleteClientDialog={setDisplayDeleteClientDialog}
        setEventClickedObject={setEventClickedObject}
        prettifyTime={prettifyTime} 
        user={user}
        clientList={clientList}
        setClientList={setClientList}
        caretakerList={caretakerList}
        setCaretakerList={setCaretakerList}
        userList={userList}
        setUserList={setUserList}
        firestore={firestore}
        setLoading={setLoading}
        //checkEmail={checkEmail}
        eventClickedObject={eventClickedObject}
        editTimeWorked={editTimeWorked}
        addShift={addShift}
        deleteShift={deleteShift}
        deleteFutureShifts={deleteFutureShifts}
        addCaretaker={addCaretaker}
        setCaretakerToDeleteID={setCaretakerToDeleteID}
        setCaretakerToDeleteName={setCaretakerToDeleteName}
        caretakerToDeleteName={caretakerToDeleteName}
        caretakerToDeleteID={caretakerToDeleteID}
        setClientToDeleteID={setClientToDeleteID}
        setClientToDeleteName={setClientToDeleteName}
        clientToDeleteID={clientToDeleteID}
        clientToDeleteName={clientToDeleteName}
        addAppointment={addAppointment}
        appointmentList={appointmentList}
        setAppointmentList={setAppointmentList}
        addUser={addUser}
        userToDeleteID={userToDeleteID}
        setUserToDeleteID={setUserToDeleteID}
        addClient={addClient}
        renderRepeatOptions={renderRepeatOptions}
        setRenderRepeatOptions={setRenderRepeatOptions}
        setCaretakerListNames={setCaretakerListNames}
        caretakerListNames={caretakerListNames}
        editInfoForShift={editInfoForShift}
        filterButtons={filterButtons}
        setFilterButtons={setFilterButtons}
        filteredEventList={filteredEventList}
        setFilteredEventList={setFilteredEventList}
        masterEventList={masterEventList}
        setMasterEventList={setMasterEventList}
        setFilterButtonsArray={setFilterButtonsArray}
        caretakerMasterList={caretakerMasterList}
        setCaretakerMasterList={setCaretakerMasterList}
        caretakerMasterListNames={caretakerMasterListNames}
        setCaretakerMasterListNames={setCaretakerMasterListNames}
        displayEditAppointmentDialog={displayEditAppointmentDialog}
        setDisplayEditAppointmentDialog={setDisplayEditAppointmentDialog}
        setAptClickedObject={setAptClickedObject}
        aptClickedObject={aptClickedObject}
        setAptToDeleteID={setAptToDeleteID}
        deleteApt={deleteApt}
        displayConfirmDeleteAppointmentDialog={displayConfirmDeleteAppointmentDialog}
        setDisplayConfirmDeleteAppointmentDialog={setDisplayConfirmDeleteAppointmentDialog}
        editAptNotes={editAptNotes}
        renderFlatFeeOptions={renderFlatFeeOptions}
        setRenderFlatFeeOptions={setRenderFlatFeeOptions}
        currentStartDateString={currentStartDateString}
        setCurrentStartDateString={setCurrentStartDateString}
        currentEndDateString={currentEndDateString}
        setCurrentEndDateString={setCurrentEndDateString}
        clientListNames={clientListNames}
        setClientListNames={setClientListNames}
        weeksLoaded={weeksLoaded}
        setWeeksLoaded={setWeeksLoaded}
        renderOvernightOptions={renderOvernightOptions}
        setRenderOvernightOptions={setRenderOvernightOptions}
        filterButtonsClient={filterButtonsClient}
        setFilterButtonsClient={setFilterButtonsClient}
        setFilterButtonsArrayClient={setFilterButtonsArrayClient}
        filterButtonsArrayClient={filterButtonsArrayClient}
        currWeekStartTimeSecs={currWeekStartTimeSecs}
        setCurrWeekStartTimeSecs={setCurrWeekStartTimeSecs}
        currWeekEndTimeSecs={currWeekEndTimeSecs}
        setCurrWeekEndTimeSecs={setCurrWeekEndTimeSecs}
        clientMasterList={clientMasterList}
        clientMasterListNames={clientMasterListNames}
        setClientMasterList={setClientMasterList}
        setClientMasterListNames={setClientMasterListNames}
        displayDeactivatedClientsDialog={displayDeactivatedClientsDialog}
        setDisplayDeactivatedClientsDialog={setDisplayDeactivatedClientsDialog}
        deactivatedClientsList={deactivatedClientsList}
        setDeactivatedClientsList={setDeactivatedClientsList}
        deactivatedCaretakersList={deactivatedCaretakersList}
        setDeactivatedCaretakersList={setDeactivatedCaretakersList}
        restoreClient={restoreClient}
        restoreCaretaker={restoreCaretaker}

      />}
    </div>
  );
}

export default App;


