import React, { useEffect } from "react";
import "./hoursBox.css";

export const HoursBox = (props) => {
  useEffect(() => {
    getCaretakers();
    getDates();
    growBox();
  });

  let magicNumber = 0;

  const growBox = () => {
    magicNumber = magicNumber + props.caretakerList.length;
    //    console.log("magic number!");
    //    console.log(magicNumber);
    if (magicNumber < 8) {
      document.getElementById("hoursBoxDiv").style.height = "100vh";
    } else if (magicNumber < 13) {
      document.getElementById("hoursBoxDiv").style.height = "150vh";
    } else if (magicNumber < 18) {
      document.getElementById("hoursBoxDiv").style.height = "200vh";
    } else if (magicNumber < 21) {
      document.getElementById("hoursBoxDiv").style.height = "250vh";
    } else if (magicNumber < 25) {
      document.getElementById("hoursBoxDiv").style.height = "300vh";
    } else if (magicNumber < 28) {
      document.getElementById("hoursBoxDiv").style.height = "350vh";
    } else if (magicNumber < 32) {
      document.getElementById("hoursBoxDiv").style.height = "420vh";
    }
  };

  const getDates = () => {
    var h3Date = document.getElementById("currentWeekHeader");
    var endDateDisplay = props.currentEndDate;

    let dateParseTimestamp = Date.parse(endDateDisplay);
    let trueEndDateDisplay = new Date(dateParseTimestamp);
    trueEndDateDisplay.setDate(trueEndDateDisplay.getDate() - 1);
    let trueEndDateDisplayMonth = trueEndDateDisplay.getMonth() + 1;
    let trueEndDateDisplayDay = trueEndDateDisplay.getDate();
    let trueEndDateDisplayYear = trueEndDateDisplay.getFullYear();

    let finalEndDateDisplayString = `${trueEndDateDisplayMonth}/${trueEndDateDisplayDay}/${trueEndDateDisplayYear}`;

    //   console.log("end date display");
    //   console.log(finalEndDateDisplayString);

    h3Date.innerHTML = `${props.currentStartDate} - ${finalEndDateDisplayString}`;

    // this.props.setCurrentEndDateString(finalEndDateDisplayString);
  };

  const determinePay = (payArray, currentEndOfWeek) => {
    let payKeys = Object.keys(payArray).sort();
    //    console.log("pay keys sort");
    //    console.log(payKeys);
    for (let i = 1; i < payKeys.length; i++) {
      if (currentEndOfWeek < payKeys[i]) {
        //            console.log("less than paykeys")
        return payArray[payKeys[i]];
      }
    }
    return payArray[0];
  };

  const miscInSpan = (shiftsInCurrentDateSpan) => {
    let currMiscTotal = 0;

    for (let i = 0; i < shiftsInCurrentDateSpan.length; i++) {
      if (shiftsInCurrentDateSpan[i].isFlatFee) {
        console.log("flat fee detected");
        console.log(shiftsInCurrentDateSpan[i].caretaker);
        console.log(shiftsInCurrentDateSpan[i].client);
        console.log(shiftsInCurrentDateSpan[i].flatFeeAmount);
        currMiscTotal += Number(shiftsInCurrentDateSpan[i].flatFeeAmount);
      }
    }

    return Number(currMiscTotal);
  };

  const mileageInSpan = (shiftsInCurrentDateSpan) => {
    let mileageTotal = 0;

    //console.log("mileage in span");

    for (let i = 0; i < shiftsInCurrentDateSpan.length; i++) {
      // console.log(shiftsInCurrentDateSpan[i].caretaker);
      // console.log(shiftsInCurrentDateSpan[i].shiftMileage);
      if (
        shiftsInCurrentDateSpan[i].shiftMileage !== undefined &&
        shiftsInCurrentDateSpan[i].shiftMileage !== ""
      ) {
        mileageTotal += Number(shiftsInCurrentDateSpan[i].shiftMileage);
      }
    }

    return mileageTotal;
  };

  const minsInSpan = (shiftsInCurrentDateSpan) => {
    // console.log("In mins in span");
    let minutesWorked = 0;
    for (let i = 0; i < shiftsInCurrentDateSpan.length; i++) {
      // console.log("In loop")
      let currDateStart;
      let currDateEnd;
      let diff;
      let diffMins = 0;

      if (
        shiftsInCurrentDateSpan[i].editedStartTime == "" &&
        !shiftsInCurrentDateSpan[i].isFlatFee &&
        shiftsInCurrentDateSpan[i].client != "Cleaning"
      ) {
        currDateStart = new Date(shiftsInCurrentDateSpan[i].start);
        currDateEnd = new Date(shiftsInCurrentDateSpan[i].end);
        diff = currDateEnd.getTime() - currDateStart.getTime();
        diffMins = Number(diff) / 1000 / 60;
      } else if (
        !shiftsInCurrentDateSpan[i].isFlatFee &&
        shiftsInCurrentDateSpan[i].client != "Cleaning"
      ) {
        currDateStart = new Date(shiftsInCurrentDateSpan[i].originalStartTime);
        currDateEnd = new Date(shiftsInCurrentDateSpan[i].originalEndTime);
        diff = currDateEnd.getTime() - currDateStart.getTime();
        diffMins = Number(diff) / 1000 / 60;
      }

      minutesWorked += diffMins;
    }
    return minutesWorked;
  };

  const minsInSpanMisc = (shiftInCurrentDateSpan) => {
    //  console.log("In mins in span misc");
    let minutesWorked = 0;

    //  console.log(shiftInCurrentDateSpan.start);

    let currDateStart = new Date(shiftInCurrentDateSpan.start);
    let currDateEnd = new Date(shiftInCurrentDateSpan.end);

    //  console.log("currDateStart");
    //  console.log(currDateStart);
    //  console.log("currDateEnd")
    //  console.log(currDateEnd)

    let diff = currDateEnd.getTime() - currDateStart.getTime();
    let diffMins = Number(diff) / 1000 / 60;
    minutesWorked += diffMins;

    return minutesWorked;
  };

  const minsInSpanEdited = (shiftsInCurrentDateSpan) => {
    let minutesWorked = 0;
    for (let i = 0; i < shiftsInCurrentDateSpan.length; i++) {
      let currDateStart;
      let currDateEnd;
      let diff;
      let diffMins = 0;

      if (
        shiftsInCurrentDateSpan[i].editedStartTime !== "" &&
        shiftsInCurrentDateSpan[i].editedEndTime !== "" &&
        !shiftsInCurrentDateSpan[i].isFlatFee &&
        shiftsInCurrentDateSpan[i].client != "Cleaning"
      ) {
        let debugStart = new Date(shiftsInCurrentDateSpan[i].start);
        let debugEnd = new Date(shiftsInCurrentDateSpan[i].end);
        let debugDiff = debugEnd.getTime() - debugStart.getTime();
        let debugDiffMins = Number(debugDiff) / 1000 / 60;

        currDateStart = new Date(shiftsInCurrentDateSpan[i].editedStartTime);
        currDateEnd = new Date(shiftsInCurrentDateSpan[i].editedEndTime);
        diff = currDateEnd.getTime() - currDateStart.getTime();
        diffMins = Number(diff) / 1000 / 60;
      } else if (
        !shiftsInCurrentDateSpan[i].isFlatFee &&
        shiftsInCurrentDateSpan[i].client != "Cleaning"
      ) {
        currDateStart = new Date(shiftsInCurrentDateSpan[i].start);
        currDateEnd = new Date(shiftsInCurrentDateSpan[i].end);
        diff = currDateEnd.getTime() - currDateStart.getTime();
        diffMins = Number(diff) / 1000 / 60;
      }

      minutesWorked += diffMins;
    }
    return minutesWorked;
  };

  const hoursInSpan = (mins) => {
    return (mins / 60).toFixed(2);
  };

  const getCaretakers = () => {
    //  console.log("get caretakers - master list check");
    //   console.log(props.caretakerMasterList);
    //  console.log("caretaker list - normal");
    //  console.log(props.caretakerList);

    var table = document.getElementById("caretakerTable");
    table.innerHTML = "";

    var labelRow = document.createElement("tr");
    var labelName = document.createElement("td");
    var labelScheduledHours = document.createElement("td");
    var labelActualHours = document.createElement("td");
    var labelPayRate = document.createElement("td");
    var labelHourlyTotal = document.createElement("td");
    var labelMiscPay = document.createElement("td");
    var labelMileagePay = document.createElement("td");
    var labelTotal = document.createElement("td");

    labelName.innerHTML = "Name";
    labelScheduledHours.innerHTML = "Scheduled Hours";
    labelActualHours.innerHTML = "Actual Hours";
    labelPayRate.innerHTML = "Pay Rate";
    labelHourlyTotal.innerHTML = "Hourly Total";
    labelMiscPay.innerHTML = "Flat Rates";
    labelMileagePay.innerHTML = "Mileage Pay";
    labelTotal.innerHTML = "Total Wages";

    labelName.style.fontWeight = "700";
    labelScheduledHours.style.fontWeight = "700";
    labelActualHours.style.fontWeight = "700";
    labelPayRate.style.fontWeight = "700";
    labelHourlyTotal.style.fontWeight = "700";
    labelMiscPay.style.fontWeight = "700";
    labelMileagePay.style.fontWeight = "700";
    labelTotal.style.fontWeight = "700";

    labelName.className = "hoursBoxLabel";
    labelScheduledHours.className = "hoursBoxLabel";
    labelActualHours.className = "hoursBoxLabel";
    labelPayRate.className = "hoursBoxLabel";
    labelHourlyTotal.className = "hoursBoxLabel";
    labelMiscPay.className = "hoursBoxLabel";
    labelMileagePay.className = "hoursBoxLabel";
    labelTotal.className = "hoursBoxLabel";

    labelRow.appendChild(labelName);
    labelRow.appendChild(labelScheduledHours);
    labelRow.appendChild(labelActualHours);
    labelRow.appendChild(labelPayRate);
    labelRow.appendChild(labelHourlyTotal);
    labelRow.appendChild(labelMiscPay);
    labelRow.appendChild(labelMileagePay);
    labelRow.appendChild(labelTotal);

    table.appendChild(labelRow);

    let sorted = props.caretakerMasterList.sort(function (a, b) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    for (const caretakerName of sorted) {
      // console.log("caretakername");
      // console.log(caretakerName);

      let startOfWeekCurrentDate = new Date(props.currentStartDate);

      if (
        !caretakerName.isDeactivated ||
        (caretakerName.isDeactivated &&
          startOfWeekCurrentDate.getTime() < caretakerName.deactivationTime)
      ) {
        const shiftsInCurrentDateSpan = props.eventList.filter((event) => {
          let shiftStart = new Date(event.start);
          // let shiftEnd = new Date(event.end);
          let currentStart = new Date(props.currentStartDate);
          let currentEnd = new Date(props.currentEndDate);
          return (
            shiftStart > currentStart &&
            shiftStart < currentEnd &&
            event.caretaker === caretakerName.name
          );
        });

        const minutesWorkedInSpan = minsInSpan(shiftsInCurrentDateSpan);
        const hoursWorkedInSpan = Number(hoursInSpan(minutesWorkedInSpan));
        const minutesWorkedInSpanEdited = minsInSpanEdited(
          shiftsInCurrentDateSpan
        );
        const hoursWorkedInSpanEdited = hoursInSpan(minutesWorkedInSpanEdited);
        let payRate;

        let payArray = caretakerName.pay;
        let currentEndOfWeek = new Date(props.currentEndDate).getTime();

        //console.log(determinePay(payArray, currentEndOfWeek));
        payRate = determinePay(payArray, currentEndOfWeek);

        const hourlyTotal = (payRate * hoursWorkedInSpanEdited).toFixed(2);
        const misc = miscInSpan(shiftsInCurrentDateSpan);
        const miscDisplay = misc.toFixed(2);
        const mileage = mileageInSpan(shiftsInCurrentDateSpan);
        const mileageDisplay = mileage.toFixed(2);
        const total =
          "$" + (payRate * hoursWorkedInSpanEdited + misc + mileage).toFixed(2);

        /*
                This section creates the overall hours and pay information for each caretaker
            */
        const tr = document.createElement("tr");
        tr.className = "caretakerRowHours";

        const nameTD = document.createElement("td");
        nameTD.innerHTML = caretakerName.name;
        nameTD.className = "caretakerHoursBoxTD";
        tr.appendChild(nameTD);

        const scheduledHoursTD = document.createElement("td");
        scheduledHoursTD.innerHTML = hoursWorkedInSpan.toFixed(2);
        scheduledHoursTD.className = "caretakerHoursBoxTD";
        tr.appendChild(scheduledHoursTD);

        const actualHoursTD = document.createElement("td");
        actualHoursTD.innerHTML = hoursWorkedInSpanEdited;
        actualHoursTD.className = "caretakerHoursBoxTD";
        tr.appendChild(actualHoursTD);

        const payRateTD = document.createElement("td");
        payRateTD.innerHTML = payRate;
        payRateTD.className = "caretakerHoursBoxTD";
        tr.appendChild(payRateTD);

        const hourlyTotalTD = document.createElement("td");
        hourlyTotalTD.innerHTML = hourlyTotal;
        hourlyTotalTD.className = "caretakerHoursBoxTD";
        tr.appendChild(hourlyTotalTD);

        const miscTD = document.createElement("td");
        miscTD.innerHTML = miscDisplay;
        miscTD.className = "caretakerHoursBoxTD";
        tr.appendChild(miscTD);

        const mileageTD = document.createElement("td");
        mileageTD.innerHTML = mileageDisplay;
        mileageTD.className = "caretakerHoursBoxTD";
        tr.appendChild(mileageTD);

        const totalTD = document.createElement("td");
        totalTD.innerHTML = total;
        totalTD.className = "caretakerHoursBoxTD";
        tr.appendChild(totalTD);

        table.appendChild(tr);

        /*
                This section creates the by-client hours and pay totals for each caretaker
            */

        let byClientHours = {};

        let byClientHoursEdited = {};

        let byClientMileage = {};

        for (const shiftInfo of shiftsInCurrentDateSpan) {
          const shiftInfoPackedInArray = [];
          shiftInfoPackedInArray.push(shiftInfo);

          const minutesWorkedInShift = minsInSpan(shiftInfoPackedInArray);
          const hoursWorkedInShift = hoursInSpan(minutesWorkedInShift);

          const minutesWorkedInShiftEdited = minsInSpanEdited(
            shiftInfoPackedInArray
          );
          const hoursWorkedInShiftEdited = hoursInSpan(
            minutesWorkedInShiftEdited
          );

          const clientMileage = mileageInSpan(shiftInfoPackedInArray);

          if (byClientHours.hasOwnProperty(shiftInfo.client)) {
            byClientHours[shiftInfo.client] += Number(hoursWorkedInShift);
          } else {
            byClientHours[shiftInfo.client] = Number(hoursWorkedInShift);
          }

          if (byClientHoursEdited.hasOwnProperty(shiftInfo.client)) {
            byClientHoursEdited[shiftInfo.client] += Number(
              hoursWorkedInShiftEdited
            );
          } else {
            byClientHoursEdited[shiftInfo.client] = Number(
              hoursWorkedInShiftEdited
            );
          }

          if (byClientMileage.hasOwnProperty(shiftInfo.client)) {
            byClientMileage[shiftInfo.client] += Number(clientMileage);
          } else {
            byClientMileage[shiftInfo.client] = Number(clientMileage);
          }
        }

        for (const byClient in byClientHours) {
          // console.log("by client")
          // console.log(byClient);

          let clientObj =
            props.clientList &&
            props.clientList.filter((e) => e.name == byClient)[0];
          // console.log(clientObj);

          if (clientObj && byClient != "Cleaning") {
            magicNumber = magicNumber + 0.5;

            const trByClient = document.createElement("tr");
            trByClient.className = "trByClient";

            const byClientNameTD = document.createElement("td");
            byClientNameTD.innerHTML = byClient;
            byClientNameTD.className = "byClientTD";
            trByClient.appendChild(byClientNameTD);

            const byClientHoursTD = document.createElement("td");
            byClientHoursTD.innerHTML = byClientHours[byClient].toFixed(2);
            byClientHoursTD.className = "byClientTD";
            trByClient.appendChild(byClientHoursTD);

            const byClientHoursEditedTD = document.createElement("td");
            byClientHoursEditedTD.innerHTML =
              byClientHoursEdited[byClient].toFixed(2);
            byClientHoursEditedTD.className = "byClientTD";
            trByClient.appendChild(byClientHoursEditedTD);

            const byClientPayTD = document.createElement("td");
            byClientPayTD.className = "byClientTD";
            byClientPayTD.innerHTML = payRate;
            trByClient.appendChild(byClientPayTD);

            const byClientHourlyTotalTD = document.createElement("td");
            byClientHourlyTotalTD.className = "byClientTD";
            byClientHourlyTotalTD.innerHTML =
              "$" +
              (payRate * byClientHoursEdited[byClient].toFixed(2)).toFixed(2);
            trByClient.appendChild(byClientHourlyTotalTD);

            const byClientMiscTD = document.createElement("td");
            byClientMiscTD.className = "byClientTD";
            byClientMiscTD.innerHTML = 0; //miscInSpan(shiftInfoPackedInArray);
            trByClient.appendChild(byClientMiscTD);

            const byClientMileageTD = document.createElement("td");
            byClientMileageTD.className = "byClientTD";
            byClientMileageTD.innerHTML = byClientMileage[byClient].toFixed(2);
            trByClient.appendChild(byClientMileageTD);

            const byClientTotalTD = document.createElement("td");
            byClientTotalTD.className = "byClientTD";
            byClientTotalTD.innerHTML =
              "$" +
              (payRate * byClientHoursEdited[byClient].toFixed(2)).toFixed(2);
            trByClient.appendChild(byClientTotalTD);

            table.appendChild(trByClient);
          }
        }
      }
    }
  };

  return (
    <div className="HoursBoxDiv" id="hoursBoxDiv">
      <a name="hoursTarget" />
      <h2 id="hoursHeader">Caretaker Hours</h2>
      <h3 id="currentWeekHeader"></h3>
      <table id="caretakerTable"></table>
    </div>
  );
};
