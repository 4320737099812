import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from 'react-select';
import { CaretakerSelect } from './CaretakerSelect';
import { ClientSelect } from './ClientSelect';
import './addShiftFormDialog.css';

export default function AddShiftFormDialog(props) {
  const [open, setOpen] = useState(false);


  useEffect(() => {
    setOpen(props.displayDialog);
  })

  const putDateInBox = () => {

    if (props.eventClickedObject.event) {
      var dateStartObj = new Date(props.eventClickedObject.event.start);
      var dateStartObjYear = dateStartObj.getFullYear().toString();
      var dateStartObjMonth = (dateStartObj.getMonth() + 1).toString();
      var dateStartObjDay = dateStartObj.getDate().toString();

      if (dateStartObjMonth.length < 2) {
        dateStartObjMonth = "0" + dateStartObjMonth;
      }

      if (dateStartObjDay.length < 2) {
        dateStartObjDay = "0" + dateStartObjDay;
      }

      return `${dateStartObjYear}-${dateStartObjMonth}-${dateStartObjDay}`;

    }

  }

  const putStartTimeInBox = () => {
    if (props.eventClickedObject.event) {
      console.log("put start time in box");
      
      var ogDateStartObj = new Date(props.eventClickedObject.event.start);

      //console.log(ogDateStartObj);
      var ogTimeStart = ogDateStartObj.toTimeString().split(" ")[0];
    //  console.log("Time start")
    //  console.log(ogTimeStart);
      return ogTimeStart;
    }
  
  }

  const putMileageAmountInBox = () => {
    if (props.eventClickedObject.event) {
      console.log(props.eventClickedObject.event.extendedProps.shiftMileage);
      return props.eventClickedObject.event.extendedProps.shiftMileage ? props.eventClickedObject.event.extendedProps.shiftMileage : 0;
    }
  }

  const putEndTimeInBox = () => {
    if (props.eventClickedObject.event) {
    //  console.log("put end time in box");

      var ogDateEndObj = new Date(props.eventClickedObject.event.end);
      
      //console.log(ogDateEndObj);
      var ogTimeEnd = ogDateEndObj.toTimeString().split(" ")[0];
   //   console.log("Time end");
   //   console.log(ogTimeEnd);
      return ogTimeEnd;
    }
  }

  const handleClose = () => {
    props.setDisplayDialog(false);
  };

  const handleDelete = () => {
    props.deleteShift(props.eventClickedObject);
    handleClose();
    
  }

  const handleDeleteRepeating = () => {
    console.log("handle repeat");
    props.deleteFutureShifts(props.eventClickedObject);
    handleClose();
  }

  const putFlatFeeInBox = () => {
    if (props.eventClickedObject.event) {
      return Number(props.eventClickedObject.event.extendedProps.flatFeeAmount);
    }
  }

  const putFlatFeeClientInBox = () => {
    if (props.eventClickedObject.event) {
      return Number(props.eventClickedObject.event.extendedProps.flatFeeAmountClient);
    }
  }



  async function handleAdd() {
    console.log("HANDLE ADD");
    console.log(props.eventClickedObject.event)
    console.log(props.eventClickedObject.event.start)
    console.log(props.eventClickedObject.event.startStr);
    let startStr = props.eventClickedObject.event.startStr.split(":00-")[0];
    let endStr = props.eventClickedObject.event.endStr.split(":00-")[0];
    let dateStrStart = props.eventClickedObject.event.startStr.split("T")[0];
    let dateEndStrStart= props.eventClickedObject.event.endStr.split("T")[0];
    var ogCaretakerForShiftTime = props.eventClickedObject.event.extendedProps.caretaker;
    var ogClientForShift = props.eventClickedObject.event.extendedProps.client;
    var ogFlatFee = props.eventClickedObject.event.extendedProps.isFlatFee ? props.eventClickedObject.event.extendedProps.flatFeeAmount : null;
    var ogFlatFeeClient = props.eventClickedObject.event.extendedProps.isFlatFee ? props.eventClickedObject.event.extendedProps.flatFeeAmountClient : null;
    
    // caretaker is logged in
    if (!props.checkAdminPriviledge()) {
      let editedTimeStart = dateStrStart + "T" + document.getElementById("timeStartDialogCT").value;
      let editedTimeEnd = dateEndStrStart + "T" + document.getElementById("timeEndDialogCT").value;
      let originalTimeStart = dateStrStart + "T" + putStartTimeInBox();
      let originalTimeEnd = dateEndStrStart + "T" + putEndTimeInBox();
    //  console.log("TIME START Value");
    //  console.log(editedTimeStart);
    //  console.log("TIME END Value");
    //  console.log(editedTimeEnd);
      props.editTimeWorked(startStr, editedTimeStart, editedTimeEnd, 
        ogCaretakerForShiftTime, ogClientForShift, originalTimeStart, originalTimeEnd); 
    } else {
      // Admin is logged in 
      let adjustedTimeStart = document.getElementById("timeStartDialogAdmin").value;
      let adjustedTimeEnd = document.getElementById("timeEndDialogAdmin").value;  

      let adjustedDate = document.getElementById("adjustDateDialogAdmin").value;
      console.log("adjusted date");
      console.log(adjustedDate);

    //  console.log("OG Caretaker");
    //  console.log(props.eventClickedObject.event.extendedProps.caretaker);
      var updatedCaretakerForShift = document.getElementById("caretakerSelectDropdown1").value;
      var updatedClientForShift = document.getElementById("clientSelectDropdown1").value;

      var updatedMileageValue = document.getElementById("mileageEdit").value;

      var overwriteTimeStart;
      var overwriteTimeEnd;


      if (dateStrStart != adjustedDate) {
        overwriteTimeStart = adjustedDate + "T" + adjustedTimeStart;
        overwriteTimeEnd = adjustedDate + "T" + adjustedTimeEnd;
      } else {
        overwriteTimeStart = dateStrStart + "T" + adjustedTimeStart;
        overwriteTimeEnd = dateEndStrStart + "T" + adjustedTimeEnd;
      }

      var updatedFlatFeeAmount = document.getElementById("flatFeeAdjustInput") && document.getElementById("flatFeeAdjustInput").value;
      var updatedFlatFeeAmountClient = document.getElementById("flatFeeClientAdjustInput") && document.getElementById("flatFeeClientAdjustInput").value;


      await props.editInfoForShift(startStr, endStr, ogCaretakerForShiftTime, updatedCaretakerForShift, ogClientForShift, updatedClientForShift, overwriteTimeStart, overwriteTimeEnd, updatedFlatFeeAmount, updatedMileageValue, updatedFlatFeeAmountClient);
      

     
    }
    
    props.setDisplayDialog(false);
  }

  
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Shift Info</DialogTitle>
        <DialogContent>
          {/* Renders only for caretakers - used to adjust time actually worked */}
          {!props.checkAdminPriviledge() &&
            <div>
              <DialogContentText>
                Please enter the time you worked.
              </DialogContentText>
              <label for="timeStartDialogCT" id="timeStartDialogLabelCT">Start Time:</label><br />
              <input type="time" id="timeStartDialogCT" defaultValue={putStartTimeInBox()}></input>
              <br />
              <label for="timeEndDialogCT" id="timeEndDialogLabelCT">End Time:</label><br />
              <input type="time" id="timeEndDialogCT" defaultValue={putEndTimeInBox()}></input>
            </div>
          }
           {/* Renders only for Admin side - used to adjust shifts original times */}
          {props.checkAdminPriviledge() &&
          <div>
            <DialogContentText>
                Use to adjust shifts start/end times, or change caretaker for shift.
            </DialogContentText>
            <label for="adjustDateDialogAdmin" id="adjustDateDialogLabelAdmin">Adjust Date</label><br />
            <input type="date" id="adjustDateDialogAdmin" defaultValue={putDateInBox()}></input>
            <br /><br />
            <label for="timeStartDialogAdmin" id="timeStartDialogLabelAdmin">Adjust Start Time</label><br />
            <input type="time" id="timeStartDialogAdmin" defaultValue={putStartTimeInBox()}></input>
            <br /><br />
            <label for="timeEndDialogAdmin" id="timeEndDialogLabelAdmin">Adjust End Time</label><br />
            <input type="time" id="timeEndDialogAdmin" defaultValue={putEndTimeInBox()}></input>
            <br /><br />
            <label htmlFor="tradeShiftClientSelect">
              Client for shift
            </label>
            <ClientSelect eventClickedObj={props.eventClickedObject.event} clientListNames={props.clientListNames} clientSelectID={"1"}/>
            <br/>
            <label htmlFor="tradeShiftSelect">
              Caretaker for shift
            </label>
            <CaretakerSelect eventClickedObj={props.eventClickedObject.event} caretakerListNames={props.caretakerListNames} caretakerSelectID={"1"}/>
            <br />
            <label htmlFor="mileageEdit">
              Mileage Payment Amount
            </label>
            <input type="number" id="mileageEdit" defaultValue={putMileageAmountInBox()}></input>
            {props.eventClickedObject.event && props.eventClickedObject.event.extendedProps.isFlatFee && 
              <div>
                <label htmlFor="flatFeeAdjustInput">Flat Fee Amount</label>
                <input type="number" id="flatFeeAdjustInput" defaultValue={putFlatFeeInBox()}></input>
                <label htmlFor="flatFeeClientAdjustInput">Flat Fee Amount Client</label>
                <input type="number" id="flatFeeClientAdjustInput" defaultValue={putFlatFeeClientInBox()}></input>
              </div>
              
            }
          </div>
          }<br />

        </DialogContent>
        <DialogActions>

        

        {props.checkAdminPriviledge() && props.eventClickedObject.event && props.eventClickedObject.event.extendedProps.repeatingShift === true &&
         <Button onClick={handleDeleteRepeating} id="deleteRepeatingButton">
          Delete All Future Shifts
          </Button>
        }

        
        {props.checkAdminPriviledge() && <Button onClick={handleDelete} id="deleteButton">
            Delete Shift
          </Button>
        }
          <Button onClick={handleClose} id="cancelButton">
            Cancel
          </Button>
          <Button onClick={handleAdd} id="confirmShiftDetailsButton">
            Confirm shift details
          </Button>
        
        </DialogActions>
      </Dialog>
      
    </div>

    
  );
}