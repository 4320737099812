import React from "react";
import "./addClientBox.css";
import { Button } from "./Button";

export const AddClientBox = (props) => {
  const gatherFields = () => {
    let clientName = document.getElementById("clientNameInput").value.trim();
    let clientRate = document.getElementById("clientRateInput").value;
    props.addClient(clientName, clientRate);
    alert("Client successfully added");
  };

  return (
    <div className="addClientDiv">
      <a name="addClientTarget"></a>
      <h2>Add Client</h2>
      <div className="formSep"></div>
      <div className="addClientFormDiv">
        <div className="clientInfoDiv">
          <label for="clientNameInput" id="clientNameLabel">
            Name:{" "}
          </label>
          <input type="text" id="clientNameInput" required></input>

          <div className="formSep"></div>

          <label for="clientRateInput" id="clientRateLabel">
            Rate:{" "}
          </label>
          <input type="number" id="clientRateInput" required></input>

          <div className="formSep"></div>
        </div>
        <Button
          onClick={() => gatherFields()}
          type="button"
          buttonStyle="btn--success--solid"
          buttonSize="btn--medium"
        >
          Add Client
        </Button>
      </div>
    </div>
  );
};
