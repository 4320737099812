import React, {useEffect} from 'react';
import './hoursBoxClients.css';

export const HoursBoxClients = (props) => {
    
    useEffect(() => {
        getClients();
        getDates();
        growBox();
    },)

    const miscInSpanClients = (shiftsInCurrentDateSpan) => {
        let currMiscTotal = 0;

        for (let i=0; i < shiftsInCurrentDateSpan.length; i++) {
 
            if (shiftsInCurrentDateSpan[i].isFlatFee) {
                console.log("flat fee detected");
                console.log(shiftsInCurrentDateSpan[i].caretaker);
                console.log(shiftsInCurrentDateSpan[i].client);
                console.log("Caretaker fee");
                console.log(shiftsInCurrentDateSpan[i].flatFeeAmount);
                console.log("Client fee");
                console.log(shiftsInCurrentDateSpan[i].flatFeeAmountClient);
                currMiscTotal += shiftsInCurrentDateSpan[i].flatFeeAmountClient === undefined || shiftsInCurrentDateSpan[i].flatFeeAmountClient === null ? 0 : Number(shiftsInCurrentDateSpan[i].flatFeeAmountClient);
            } 
            
         }
         console.log(`currMiscTotal: ${currMiscTotal}`)
        return Number(currMiscTotal);
    }

    const growBox = () => {
        if (props.clientList.length < 12) {
            document.getElementById("hoursBoxClientsDiv").style.height = "100vh";
        }
        else if (props.clientList.length < 17) {
            document.getElementById("hoursBoxClientsDiv").style.height = "125vh"; 
        }
        else if (props.clientList.length < 22) {
            document.getElementById("hoursBoxClientsDiv").style.height = "150vh"; 
        }
        else if (props.clientList.length < 27) {
            document.getElementById("hoursBoxClientsDiv").style.height = "175vh"; 
        }
        else if (props.clientList.length < 32) {
            document.getElementById("hoursBoxClientsDiv").style.height = "200vh"; 
        }
        else if (props.clientList.length < 37) {
            document.getElementById("hoursBoxClientsDiv").style.height = "250vh"; 
        }
        else if (props.clientList.length < 42) {
            document.getElementById("hoursBoxClientsDiv").style.height = "300vh"; 
        }
    }

    const getDates = () => {
        var h3DateClients = document.getElementById("currentWeekHeaderHoursClients");
        var endDateDisplay = props.currentEndDate;
        
        let dateParseTimestamp = Date.parse(endDateDisplay);
        let trueEndDateDisplay = new Date(dateParseTimestamp);
        trueEndDateDisplay.setDate(trueEndDateDisplay.getDate() - 1);
        let trueEndDateDisplayMonth = trueEndDateDisplay.getMonth() + 1;
        let trueEndDateDisplayDay = trueEndDateDisplay.getDate();
        let trueEndDateDisplayYear = trueEndDateDisplay.getFullYear();

        let finalEndDateDisplayString = `${trueEndDateDisplayMonth}/${trueEndDateDisplayDay}/${trueEndDateDisplayYear}`;
        
        console.log("end date display");
        console.log(finalEndDateDisplayString);

        h3DateClients.innerHTML = `${props.currentStartDate} - ${finalEndDateDisplayString}`;
    }

    const determineRate = (rateArray, currentEndOfWeek) => {

        let rateKeys = Object.keys(rateArray).sort();

        for (let i = 1; i < rateKeys.length; i++) {
            if (currentEndOfWeek < rateKeys[i]) {
    //            console.log("less than paykeys")
                return rateArray[rateKeys[i]];
            }
        }
        return rateArray[0];

    }

    const minsInSpan = (shiftsInCurrentDateSpan) => {
        let minutesWorked = 0;
                for (let i=0; i < shiftsInCurrentDateSpan.length; i++) {

                    if (!shiftsInCurrentDateSpan[i].isFlatFee) {
                        let currDateStart = new Date(shiftsInCurrentDateSpan[i].start);
                        let currDateEnd = new Date(shiftsInCurrentDateSpan[i].end);
    
                      //  console.log(`Currdate start: ${currDateStart}`);
                      //  console.log(`Currdate end: ${currDateEnd}`);
                        let diff = currDateEnd.getTime() - currDateStart.getTime();
                        let diffMins = Number(diff) / 1000 / 60;
                        minutesWorked += diffMins;
                      //  console.log(`Difference: ${diffMins}`);
                    }
                    
                }
                return minutesWorked;
    }

    const minsInSpanEdited = (shiftsInCurrentDateSpan) => {
        let minutesWorked = 0;
                for (let i=0; i < shiftsInCurrentDateSpan.length; i++) {
                    let currDateStart;
                    let currDateEnd;
                    let diff;
                    let diffMins;

                    if (shiftsInCurrentDateSpan[i].editedStartTime !== "" && shiftsInCurrentDateSpan[i].editedEndTime !== "" && !shiftsInCurrentDateSpan[i].isFlatFee) {
                        currDateStart = new Date(shiftsInCurrentDateSpan[i].editedStartTime);
                        currDateEnd = new Date(shiftsInCurrentDateSpan[i].editedEndTime);
                        diff = currDateEnd.getTime() - currDateStart.getTime();
                        diffMins = Number(diff) / 1000 / 60;
                        minutesWorked += diffMins;
                    } else if (!shiftsInCurrentDateSpan[i].isFlatFee) {
                        currDateStart = new Date(shiftsInCurrentDateSpan[i].start);
                        currDateEnd = new Date(shiftsInCurrentDateSpan[i].end);
                        diff = currDateEnd.getTime() - currDateStart.getTime();
                        diffMins = Number(diff) / 1000 / 60;
                        minutesWorked += diffMins;
                    }
                    
                   // console.log(`Currdate start: ${currDateStart}`);
                   // console.log(`Currdate end: ${currDateEnd}`);
                    
                    //console.log(`Difference: ${diffMins}`);
                }
                return minutesWorked;
    }

    const hoursInSpan = (mins) => {
        return (mins / 60).toFixed(2);
    }

    const getClients = () => {
        var clientTable = document.getElementById("clientTable");
        clientTable.innerHTML = '';

        var labelClientRow = document.createElement("tr");
        var labelClientName = document.createElement("td");
        var labelClientScheduledHours = document.createElement("td");
        var labelClientActualHours = document.createElement("td");
        var labelClientRate = document.createElement("td");
        var labelClientSubtotal = document.createElement("td");
        var labelClientMisc = document.createElement("td");
        var labelClientTotal = document.createElement("td");
        

        labelClientName.innerHTML="Name";
        labelClientScheduledHours.innerHTML="Scheduled Hours";
        labelClientActualHours.innerHTML="Actual Hours";
        labelClientRate.innerHTML="Rate";
        labelClientSubtotal.innerHTML = "Subtotal";
        labelClientMisc.innerHTML = "Flat Fees"
        labelClientTotal.innerHTML="Total";

        labelClientName.style.fontWeight = "700";
        labelClientScheduledHours.style.fontWeight = "700";
        labelClientActualHours.style.fontWeight = "700";
        labelClientRate.style.fontWeight = "700";
        labelClientSubtotal.style.fontWeight="700";
        labelClientMisc.style.fontWeight = "700";
        labelClientTotal.style.fontWeight = "700";

        labelClientRow.appendChild(labelClientName);
        labelClientRow.appendChild(labelClientScheduledHours);
        labelClientRow.appendChild(labelClientActualHours);
        labelClientRow.appendChild(labelClientRate);
        labelClientRow.appendChild(labelClientSubtotal);
        labelClientRow.appendChild(labelClientMisc);
        labelClientRow.appendChild(labelClientTotal);

        clientTable.appendChild(labelClientRow);

        
        for (const clientName of props.clientList) {

        //    console.log("clientname of clientlist")
        //    console.log(clientName);
         //   console.log(props.clientList);
            
            const shiftsInCurrentDateSpan = props.eventList.filter(event => {       
                    let shiftStart = new Date(event.start);
                   // let shiftEnd = new Date(event.end);
                    let currentStart = new Date(props.currentStartDate);
                    let currentEnd = new Date(props.currentEndDate);
                    return shiftStart > currentStart && shiftStart < currentEnd && event.client === clientName.name;
            });
            const clientMinutesWorkedInSpan = minsInSpan(shiftsInCurrentDateSpan);
            const clientHoursWorkedInSpan = hoursInSpan(clientMinutesWorkedInSpan);
            const clientMinutesWorkedInSpanEdited = minsInSpanEdited(shiftsInCurrentDateSpan);
            const clientHoursWorkedInSpanEdited = hoursInSpan(clientMinutesWorkedInSpanEdited);

            const rateArrayClient = clientName.rate;
            let currentEndOfWeek = new Date(props.currentEndDate).getTime();

            const clientRate = determineRate(rateArrayClient, currentEndOfWeek);



            const clientSubtotal = (clientRate * clientHoursWorkedInSpanEdited).toFixed(2);
            const clientMisc = miscInSpanClients(shiftsInCurrentDateSpan);
            const miscDisplay = clientMisc.toFixed(2);
            const clientTotal = "$" + (clientRate * clientHoursWorkedInSpanEdited + clientMisc).toFixed(2);

        
            const clientHoursTR = document.createElement("tr");

            const clientNameHoursBoxTD = document.createElement("td");
            clientNameHoursBoxTD.innerHTML = clientName.name;
            clientHoursTR.appendChild(clientNameHoursBoxTD);

            const clientScheduledHoursTD = document.createElement("td");
            clientScheduledHoursTD.innerHTML = clientHoursWorkedInSpan;
            clientHoursTR.appendChild(clientScheduledHoursTD);

            const clientActualHoursTD = document.createElement("td");
            clientActualHoursTD.innerHTML = clientHoursWorkedInSpanEdited;
            clientHoursTR.appendChild(clientActualHoursTD);

            const clientRateTD = document.createElement("td");
            clientRateTD.innerHTML = clientRate;
            clientHoursTR.appendChild(clientRateTD);

            const clientSubtotalTD = document.createElement("td");
            clientSubtotalTD.innerHTML = clientSubtotal;
            clientHoursTR.appendChild(clientSubtotalTD);

            const clientMiscTD = document.createElement("td");
            clientMiscTD.innerHTML = miscDisplay;
            clientHoursTR.appendChild(clientMiscTD);

            const clientTotalTD = document.createElement("td");
            clientTotalTD.innerHTML = clientTotal;
            clientHoursTR.appendChild(clientTotalTD);
            
            clientTable.appendChild(clientHoursTR);
        }
    }

    return (
        <div className="HoursBoxClientsDiv" id="hoursBoxClientsDiv">
            <a name="hoursBoxTargetClient"/>
            <h2 id="hoursHeaderClient">Client Hours</h2>
            <h3 id="currentWeekHeaderHoursClients"></h3>
            <table id="clientTable">

            </table>
        </div>
    )
}