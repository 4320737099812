import React, { useEffect, useState } from 'react';
import './editClientsBox.css';
import { Button } from './Button';
import { doc } from 'firebase/firestore';

export const EditClientsBox = (props) => {


    const [showingDeactivated, setShowingDeactivated] =  useState(false);
    
    useEffect(() => {
        getClientsForEditBox();
        growBox();
    })

    const growBox = () => {

        if (props.clientList.length <= 12) {
            document.getElementById("editClientsDiv").style.height = "100vh";
        } else {
            let increaseDifferenceClientNumber = props.clientList.length - 12;
            let increaseVH = (increaseDifferenceClientNumber * 7) + 100;
            let increaseVHString = increaseVH + "vh";
            document.getElementById("editClientsDiv").style.height = increaseVHString;
        }
        
    }


    const getClientsForEditBox = () => {
        
        console.log("Get clients")
        const clientEditBoxTable = document.getElementById("clientEditBoxTable");
        clientEditBoxTable.innerHTML = "";

        const clientEditBoxLabelsRow = document.createElement("tr");
        const clientEditBoxNameLabel = document.createElement("td");
        const clientEditBoxRateLabel = document.createElement("td");
        const clientRateDateEffective = document.createElement("td");

        clientEditBoxNameLabel.innerHTML = "Name";
        clientEditBoxRateLabel.innerHTML = "Rate";
        clientRateDateEffective.innerHTML = "!!! Required !!! - Date new rate takes Effect"

        clientEditBoxLabelsRow.appendChild(clientEditBoxNameLabel);
        clientEditBoxLabelsRow.appendChild(clientEditBoxRateLabel);
        clientEditBoxLabelsRow.appendChild(clientRateDateEffective);

        clientEditBoxTable.appendChild(clientEditBoxLabelsRow);

        let sorted = props.clientList.sort( function(a, b) {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        })

        for (const clientName of sorted) {
            const clientEditBoxRow = document.createElement("tr");
            const clientNameData = document.createElement("td");
            clientNameData.innerHTML = clientName.name;
            clientNameData.id = "clientNameData" + clientName.name;
            clientNameData.className = "clientNameData";

            const clientRateData = document.createElement("td");
            const clientRateInput = document.createElement("input");
            clientRateInput.id = "clientRateInput" + clientName.name;
            clientRateInput.className = "clientRateInput"
            clientRateInput.value = clientName.rate[0];
            clientRateData.appendChild(clientRateInput);

            const clientChangeRateDate = document.createElement("td");
            const clientChangeRateDateInput = document.createElement("input");
            clientChangeRateDateInput.type = "date";
            clientChangeRateDateInput.id = "clientChangeRateDateInput" + clientName.name;
            clientChangeRateDateInput.className = "clientRateDate"
            clientChangeRateDate.appendChild(clientChangeRateDateInput);

            const updateClientButtonTD = document.createElement("td");
            const updateClientButton = document.createElement("button");
            updateClientButton.type="button";
            updateClientButton.onclick = function() {
                const clientNameData = document.getElementById("clientNameData" + clientName.name).innerHTML;
                const clientRateEdit = document.getElementById("clientRateInput" + clientName.name).value;
                
                const clientIDEdit = props.clientMasterList.filter(e => e.name === clientNameData)[0].docID;
                console.log(clientIDEdit)

                let rateEffectiveDate = document.getElementById("clientChangeRateDateInput" + clientName.name).value;
                let rateEffectiveDateObj = new Date(rateEffectiveDate);
                let rateEffectTime = rateEffectiveDateObj.getTime();

                props.firestore.collection("clients").doc(clientIDEdit).get().then((doc) => {
                
                    let oldRateArrayClient = doc.data().rate;

                    let oldRateClient = oldRateArrayClient[0];

                    oldRateArrayClient[rateEffectTime] = oldRateClient;

                    oldRateArrayClient[0] = clientRateEdit;

                    props.firestore.collection("clients").doc(clientIDEdit).update({rate : oldRateArrayClient})
                });
                
                alert("Client information updated successfully");
                props.InitialLoadClients();
            };
            
            updateClientButton.innerHTML = "Update";
            updateClientButtonTD.id = "updateClientButtonTD";
            updateClientButton.className = "updateClientButton";
            updateClientButtonTD.appendChild(updateClientButton);

            const deleteClientButtonTD = document.createElement("td");
            
            const deleteClientButton = document.createElement("button");
            deleteClientButton.type="button";
            deleteClientButton.onclick = function() {
                const clientNameDelete = document.getElementById("clientNameData" + clientName.name).innerHTML;
                const clientToDelete = props.clientList.filter(e => e.name === clientNameDelete)[0];
                const clientToDeleteID = clientToDelete.docID;
                const clientToDeleteName = clientToDelete.name;
                props.setClientToDeleteID(clientToDeleteID);
                props.setClientToDeleteName(clientToDeleteName);
                props.setDisplayDeleteClientDialog(true);
                
            }
            deleteClientButton.innerHTML = "Delete / Deactivate";
            deleteClientButtonTD.id = "deleteClientButtonTD";
            deleteClientButton.className = "deleteClientButton";
            deleteClientButtonTD.appendChild(deleteClientButton);

            clientEditBoxRow.appendChild(clientNameData);
            clientEditBoxRow.appendChild(clientRateData);
            clientEditBoxRow.appendChild(clientChangeRateDate);
            clientEditBoxRow.appendChild(updateClientButtonTD);
            clientEditBoxRow.appendChild(deleteClientButtonTD);
            clientEditBoxTable.appendChild(clientEditBoxRow);
        }

        
    } 


    const showDeactivatedClients = () => {

        let deactivatedClientsTable =  document.getElementById("deactivatedClientsTable");


        // Deactivated clients are already being shown on click, so close them
        if (showingDeactivated) {
            setShowingDeactivated(false);
            console.log("not showing");

            // reset deactivated clients div size to default 100
            document.getElementById("deactivatedClientsTable").style.height = "0vh";

            deactivatedClientsTable.innerHTML = "";

            
            if (props.clientList.length <= 12) {
                document.getElementById("editClientsDiv").style.height = "100vh";
            } else {
                let increaseDifferenceClientNumber = props.clientList.length - 12;
                let increaseVH = (increaseDifferenceClientNumber * 7) + 100;
                let increaseVHString = increaseVH + "vh";
                console.log(increaseVHString);
                document.getElementById("editClientsDiv").style.height = increaseVHString;     
            }

        // Deactivated clients are NOT being shown, so show them
        } else {
            setShowingDeactivated(true);
            console.log("showing!");

            deactivatedClientsTable.innerHTML = "";
            
            // get total number of clients
            let allClientsLength = props.clientList.length + props.deactivatedClientsList.length;

           

            

            deactivatedClientsTable.style.height = "25vh";

            if (allClientsLength <= 12) {
                document.getElementById("editClientsDiv").style.height = "100vh";
            } else {
                let increaseDifferenceClientNumber = allClientsLength - 12;
                let increaseVH = (increaseDifferenceClientNumber * 9) + 110;
                let increaseVHString = increaseVH + "vh";
                console.log(increaseVHString);
                document.getElementById("editClientsDiv").style.height = increaseVHString;

                
                deactivatedClientsTable.innerHTML = "";

                console.log(props.deactivatedClientsList);


                let deactivTableLablesTR = document.createElement("tr");

                let deactivTableLabelName = document.createElement("td");
                deactivTableLabelName.innerHTML = "Name";
                deactivTableLablesTR.appendChild(deactivTableLabelName);

                let deactivTableLabelRate = document.createElement("td");
                deactivTableLabelRate.innerHTML = "Rate";
                deactivTableLablesTR.appendChild(deactivTableLabelRate);

                let deactivTableLabelDeactivationDate = document.createElement("td");
                deactivTableLabelDeactivationDate.innerHTML = "Set to 'Deactivated' on:";
                deactivTableLablesTR.appendChild(deactivTableLabelDeactivationDate);



                deactivatedClientsTable.appendChild(deactivTableLablesTR);

                for (let deactiv of props.deactivatedClientsList) {
                    console.log(deactiv);
                    let deactivClientTR = document.createElement("tr");
                    deactivClientTR.className = "deactivClientTR";

                    let deactivClientNameTD = document.createElement("td");
                    deactivClientNameTD.id = "deactivName" + deactiv.name;
                    deactivClientNameTD.innerHTML = deactiv.name;
                    deactivClientNameTD.className = "deactivTD";
                    deactivClientTR.appendChild(deactivClientNameTD);

                    let deactivClientRateTD = document.createElement("td");
                    deactivClientRateTD.innerHTML = deactiv.rate[0];
                    deactivClientRateTD.className = "deactivTD";
                    deactivClientTR.appendChild(deactivClientRateTD);

                    let deactivClientTimeTD = document.createElement("td");

                    let deactivationDate = new Date(deactiv.deactivationTime);
                    let deactivDayOfMonth = deactivationDate.getDate();
                    let deactivMonth = deactivationDate.getMonth() + 1;
                    let deactivYear = deactivationDate.getFullYear();
                    let deactivDateString = deactivMonth + "/" + deactivDayOfMonth + "/" + deactivYear;

                    deactivClientTimeTD.innerHTML = deactivDateString;
                    deactivClientTimeTD.className = "deactivTimeTD";
                    deactivClientTR.appendChild(deactivClientTimeTD);

                    let deactivClientReactivateTD = document.createElement("td");
                    let deactivClientReactivateButton = document.createElement("button");
                    deactivClientReactivateButton.type = "button";
                    deactivClientReactivateButton.innerHTML = "Reactivate";
                    deactivClientReactivateButton.className = "updateClientButton";
                    deactivClientReactivateButton.id = "reactivate-" + deactiv.name;
                    deactivClientReactivateButton.onclick = function() {
                        //const clientNameRestore = document.getElementById("deactivName" + deactiv.name).innerHTML;
                        //const clientToRestore = props.deactivatedClientsList.filter(e => e.name === clientNameRestore)[0];
                        //const clientToRestoreID = clientToRestore.docID;
                        props.restoreClient(deactiv);
                    }

                    deactivClientReactivateTD.appendChild(deactivClientReactivateButton);
                    deactivClientTR.appendChild(deactivClientReactivateTD);
                    
                    let deactivClientDeleteTD = document.createElement("td");
                    let deactivClientDeleteButton = document.createElement("button");
                    deactivClientDeleteButton.type = "button";
                    deactivClientDeleteButton.innerHTML = "Delete";
                    deactivClientDeleteButton.id = "deactiv-delete-" + deactiv.name;
                    deactivClientDeleteButton.className = "deleteClientButton";
                    deactivClientDeleteButton.onclick = function() {
                        console.log("delete clicked for " + deactiv.name);

                        const clientNameDelete = document.getElementById("deactivName" + deactiv.name).innerHTML;
                        const clientToDelete = props.deactivatedClientsList.filter(e => e.name === clientNameDelete)[0];
                        const clientToDeleteID = clientToDelete.docID;
                        const clientToDeleteName = clientToDelete.name;
                        props.setClientToDeleteID(clientToDeleteID);
                        props.setClientToDeleteName(clientToDeleteName);
                        props.setDisplayDeleteClientDialog(true);
                    }


                    deactivClientDeleteTD.appendChild(deactivClientDeleteButton);
                    deactivClientTR.appendChild(deactivClientDeleteTD);

                    deactivatedClientsTable.appendChild(deactivClientTR);

                    // test
                    
                }



            }
        }
        
        
        
    }


    return (

        <div className="editClientsDiv" id="editClientsDiv">
            <a name='editClientsTarget'></a>
            <h2>Edit Clients</h2><br />

            <Button onClick={() => showDeactivatedClients()}
            type="button"
            buttonStyle="btn--success--solid"
            buttonSize="btn--medium"
            >
              Show Deactivated Clients
            </Button>

            <table id="deactivatedClientsTable"></table>
            <div className="formSep"></div>
            <table id="clientEditBoxTable"></table>
        </div>

    )
}