import { objectExpression } from '@babel/types';
import React, { useEffect } from 'react';
import './clientSelect.css';

export const ClientSelect = (props) => {

    useEffect(() => {
        populateClientDropdown();
    });

    const populateClientDropdown = () => {
        console.log("PCD2!")
        var csd2 = document.getElementById("clientSelectDropdown" + props.clientSelectID);
        csd2.innerHTML = "";

        for (var i = 0; i < props.clientListNames.length; i++) {
            var clientNameOptionElement = document.createElement("option");
            clientNameOptionElement.text = props.clientListNames[i];
            csd2.appendChild(clientNameOptionElement);
        }

        for (var i = 0; i < props.clientListNames.length; i++) {
            
            var currClientName = props.clientListNames[i];
            
            if (props.eventClickedObj && props.eventClickedObj.extendedProps.client === currClientName) {
                csd2.selectedIndex = i;
                break;
            } 
            
        }
    }

    return (
        <div>
            <select id={"clientSelectDropdown" + props.clientSelectID}></select>
        </div>
    )
}