import React from 'react'
import { Button } from './Button'
import './signOut.css'
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

export const SignOut = (props) => {
    return props.auth.currentUser && (
        <div className="signOutDiv">
            <div className="buttonDiv">
        <Button onClick={() => props.auth.signOut()}
            type="button"
            buttonStyle="btn--danger--solid"
            buttonSize="btn--medium"
            >
            Sign Out
        </Button>
        </div>
        </div>
    )
}
