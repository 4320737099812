import React, { Component } from 'react'
import { Nav } from './Nav';
import MyCalendar from '../MyCalendar';
import { HoursBox } from './HoursBox';
import { HoursBoxClients } from './HoursBoxClients';
import { AddShiftBox } from './AddShiftBox';
import  AddShiftFormDialog  from './AddShiftFormDialog'
import DeleteCaretakerDialog from './DeleteCaretakerDialog';
import DeleteClientDialog from './DeleteClientDialog';
import { AddCaretakerBox } from './AddCaretakerBox';
import { AddAppointmentBox } from './AddAppointmentBox';
import { AddClientBox } from './AddClientBox';
import { EditCaretakersBox } from './EditCaretakersBox';
import { EditClientsBox } from './EditClientsBox';
import MyAppointments from '../MyAppointments';
import { CaretakerSelect } from './CaretakerSelect';
import { CaretakerFilter } from './CaretakerFilter';
import { CaretakerFilter2 } from './CaretakerFilter2';
import { CaretakerFilter3 } from './CaretakerFilter3';
import './home.css';
import EditAppointmentDialog from './EditAppointmentDialog';
import ConfirmDeleteAppointmentDialog from './ConfirmDeleteAptDialog';
import { MileageAdmin } from './MileageAdmin';
import { MileageInputUser } from './MileageInputUser';
import { TimeOffRequestUser } from './TimeOffRequestUser';
import { MileageInputAdmin } from './MileageInputAdmin';
import { RaiseBox } from './RaiseBox';
import DeactivatedClientsDialog from './DeactivatedClientsDialog';


export default class Home extends Component {


    constructor(props) {
        super(props);
        this.InitialLoadCaretakers = this.InitialLoadCaretakers.bind(this);
        this.InitialLoadClients = this.InitialLoadClients.bind(this);
        this.InitialLoadShifts = this.InitialLoadShifts.bind(this);
        this.InitialLoadUsers = this.InitialLoadUsers.bind(this);
    }

    


    componentDidMount() {
        
        //this.InitialLoadUsers();
        this.InitialLoadCaretakers();
        this.InitialLoadClients();
        this.InitialLoadShifts();
        this.InitialLoadAppointments();
    }

    InitialLoadUsers() {
        let users = [];
        this.props.firestore.collection("users").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
               
            let userRecord = {
             name: doc.data().name,
             email: doc.data().email,
             type: doc.data().type,
             docID: doc.id,
             isDeactivated: doc.data().isDeactivated
            }
            users.push(userRecord);
            });
            this.props.setUserList(users);
        });
    }
       
    InitialLoadClients() {
        
        let clients = [];
        let clientNames = [];
        let masterClients = [];
        let masterClientNames = [];
        let deactivatedClients = [];

        this.props.firestore.collection("clients").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                 
                let clientRecord = {
                    name: doc.data().name,
                    rate: doc.data().rate,
                    docID: doc.id,
                    isDeactivated: doc.data().isDeactivated,
                    deactivationTime: doc.data().deactivationTime
                }

                console.log("HERE IS CLIENT RECORD");
                console.log(clientRecord);

                if (clientRecord.isDeactivated != true) {

                    clientNames.push(clientRecord.name);
                    clients.push(clientRecord);
     
                    masterClientNames.push(clientRecord.name);
                    masterClients.push(clientRecord);
                } else {
                    deactivatedClients.push(clientRecord);
                    masterClientNames.push(clientRecord.name);
                    masterClients.push(clientRecord);

                }

            });

            console.log(clients);
            console.log(deactivatedClients);

            this.props.setClientList(clients);
            this.props.setClientListNames(clientNames);
            this.props.setClientMasterList(masterClients);
            this.props.setClientMasterListNames(masterClientNames);
            this.props.setDeactivatedClientsList(deactivatedClients);
        });

        
           
    }

    InitialLoadCaretakers() {
        let caretakers = [];
        let masterCaretakers = [];
        let masterCaretakersNames = [];
        let caretakersNames = [];
        let deactivatedCaretakers = [];

        this.props.firestore.collection("caretakers").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let caretakerRecord = {
                name: doc.data().name,
                clients: doc.data().clients,
                pay: doc.data().pay,
                color: doc.data().color,
                email: doc.data().email,
                isDeactivated: doc.data().isDeactivated,
                deactivationTime: doc.data().deactivationTime,
                docID: doc.id
              }

              let caretakerNameForList = doc.data().name;
              

              if (caretakerRecord.isDeactivated != true) {
                    caretakersNames.push(caretakerNameForList);
                    caretakers.push(caretakerRecord);
         
                    masterCaretakersNames.push(caretakerNameForList);
                    masterCaretakers.push(caretakerRecord);
              } else {

                    deactivatedCaretakers.push(caretakerRecord);
                    masterCaretakersNames.push(caretakerNameForList);
                    masterCaretakers.push(caretakerRecord);
              }
              
                    
            
              
            });

            this.props.setCaretakerList(caretakers);
            this.props.setCaretakerListNames(caretakersNames);
            this.props.setCaretakerMasterList(masterCaretakers);
            this.props.setCaretakerMasterListNames(masterCaretakersNames);
            this.props.setDeactivatedCaretakersList(deactivatedCaretakers);

            console.log("CARETAKER LOAD");
            console.log(caretakers);
            console.log(caretakersNames);
        })
          
    }

    InitialLoadShifts() {
        console.log("INITIAL LOAD SHIFTS - HOME COMPONENT");
        let counterForLog = 0;
        let shifts = [];
        let masterShifts = [];
        console.log("current start date ILS")
        console.log(this.props.currentStartDate);
        console.log("current end date ILS")
        console.log(this.props.currentEndDate);
        // Checks to see if the logged in user is an admin or a caretaker
        
        if (this.props.user.email === "paulrichdev@gmail.com" || this.props.user.email === "ccslovingcarellc@gmail.com") {

            let currWeekStartTime = new Date(Date.now());
            let currentToday = new Date(Date.now());
            let currWeekEndTime = new Date(Date.now());

            // Get the current day of the week value: 0 = Sunday, 1 = Monday...
            let currentDOW = currWeekStartTime.getDay();

            
            if (currentDOW == 5) {
                currWeekStartTime.setDate(currWeekStartTime.getDate() - 7); //
                currWeekEndTime.setDate(currWeekEndTime.getDate() + 14);
            
            } else if (currentDOW == 6) {
                currWeekStartTime.setDate(currWeekStartTime.getDate() - 8); //
                currWeekEndTime.setDate(currWeekEndTime.getDate() + 13);
            } else if (currentDOW == 0) {
                currWeekStartTime.setDate(currWeekStartTime.getDate() - 10);
                currWeekEndTime.setDate(currWeekEndTime.getDate() + 12); //
            } else if (currentDOW == 1) {
                currWeekStartTime.setDate(currWeekStartTime.getDate() - 11);
                currWeekEndTime.setDate(currWeekEndTime.getDate() + 11); //
            } else if (currentDOW == 2) {
                currWeekStartTime.setDate(currWeekStartTime.getDate() - 12);
                currWeekEndTime.setDate(currWeekEndTime.getDate() + 10); //
            } else if (currentDOW == 3) {
                currWeekStartTime.setDate(currWeekStartTime.getDate() - 13);
                currWeekEndTime.setDate(currWeekEndTime.getDate() + 9); //
            } else if (currentDOW == 4) {
                currWeekStartTime.setDate(currWeekStartTime.getDate() - 14);
                currWeekEndTime.setDate(currWeekEndTime.getDate() + 8); //
            }


            currWeekStartTime.setMinutes("00");
            currWeekStartTime.setHours("00");
            currWeekEndTime.setMinutes("00");
            currWeekEndTime.setHours("00");

            let currWeekStartTimeSecsFound = currWeekStartTime.getTime();
            let currWeekEndTimeSecsFound = currWeekEndTime.getTime();

            console.log("currWeekStartTime - Home")
            console.log(currWeekStartTime)
            console.log("currWeekEndTime - Home")
            console.log(currWeekEndTime);

            this.props.setCurrWeekStartTimeSecs(currWeekStartTimeSecsFound);
            this.props.setCurrWeekEndTimeSecs(currWeekEndTimeSecsFound);

        // Limited query
        // this.props.firestore.collection("shifts").where("startTimeSecs", ">", currWeekStartTimeSecsFound).where("startTimeSecs", "<", currWeekEndTimeSecsFound).limit(150).get().then((querySnapshot)  

        // Unlimitied Query
        // this.props.firestore.collection("shifts").get().then((querySnapshot)

        this.props.firestore.collection("shifts").get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
               // console.log("loading shift number: ");
               // console.log(counterForLog); 
               // console.log(doc);  
                counterForLog++;
                let shiftRecord = {
                    client: doc.data().client,
                    caretaker: doc.data().caretaker,
                    start: doc.data().startTime,
                    end: doc.data().endTime,
                    title: doc.data().client + " - " + doc.data().caretaker,
                    comments: doc.data().comments,
                    color: doc.data().color,
                    editedStartTime: doc.data().editedStartTime,
                    editedEndTime: doc.data().editedEndTime,
                    caretakerEmail: doc.data().caretakerEmail,
                    docID: doc.id,
                    repeatingShift: doc.data().repeatingShift,
                    repeatingShiftID: doc.data().repeatingShiftID,
                    startTimeSecs: doc.data().startTimeSecs,
                    originalStartTime: doc.data().originalStartTime,
                    originalEndTime: doc.data().originalEndTime,
                    isFlatFee: doc.data().isFlatFee,
                    flatFeeAmount: doc.data().flatFeeAmount,
                    flatFeeAmountClient: doc.data().flatFeeAmountClient,
                    shiftMileage: doc.data().shiftMileage
                }
            
                masterShifts.push(shiftRecord);
                shifts.push(shiftRecord);

            });
            this.props.setEventList(shifts);
            this.props.setMasterEventList(masterShifts);

            }); 
        } else {

            this.props.firestore.collection("shifts").where("caretakerEmail", "==", this.props.user.email).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {

                let shiftRecord = {
                    client: doc.data().client,
                    caretaker: doc.data().caretaker,
                    start: doc.data().startTime,
                    end: doc.data().endTime,
                    comments: doc.data().comments,
                    color: doc.data().color,
                    editedStartTime: doc.data().editedStartTime,
                    editedEndTime: doc.data().editedEndTime,
                    caretakerEmail: doc.data().caretakerEmail,
                    docID: doc.id,
                    repeatingShift: doc.data().repeatingShift,
                    repeatingShiftID: doc.data().repeatingShiftID,
                    startTimeSecs: doc.data().startTimeSecs,
                    originalStartTime: doc.data().originalStartTime,
                    originalEndTime: doc.data().originalEndTime,
                    isFlatFee: doc.data().isFlatFee,
                    flatFeeAmount: doc.data().flatFeeAmount,
                    flatFeeAmountClient: doc.data().flatFeeAmountClient,
                    shiftMileage: doc.data().shiftMileage
                }
                shifts.push(shiftRecord);
            
            });
            this.props.setEventList(shifts);
            
            });           
        }
    }

    InitialLoadAppointments() {
        
        let apts = [];
        this.props.firestore.collection("appointments").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                let aptRecord = {
                    client: doc.data().client,
                    start: doc.data().startTime,
                    end: doc.data().endTime,
                    details: doc.data().details,
                    addedBy: doc.data().addedBy,
                    aptLabel: doc.data().aptLabel,
                    aptID: doc.id
                }
                apts.push(aptRecord);
            });
            this.props.setAppointmentList(apts);
            console.log("appointments load");
            console.log(apts);
        })

    }

    render() {
        return (
            <div id="homeDiv">
                {this.props.checkAdminPriviledge() && <Nav />}
                <h1 id="schedulingH1">Scheduling</h1>

                {this.props.checkAdminPriviledge() && 
                    <CaretakerFilter3 
                        caretakerListNames={this.props.caretakerListNames} 
                        filterSelected={this.props.filterSelected}
                        setFilterButtons={this.props.setFilterButtons}
                        eventList={this.props.eventList}
                        setEventList={this.props.setEventList}
                        filteredEventList={this.props.filteredList}
                        setFilteredEventList={this.props.setFilteredList}
                        masterEventList={this.props.masterEventList}
                        setMasterEventList={this.props.setMasterEventList}
                        setFilterButtonsArray={this.props.setFilterButtonsArray}
                        caretakerMasterList={this.props.caretakerMasterList}
                        caretakerMasterListNames={this.props.caretakerMasterListNames}
                        currentStartDate={this.props.currentStartDate} 
                        clientListNames={this.props.clientListNames}
                        clientMasterList={this.props.clientMasterList}
                        filterButtonsClient={this.props.filterButtonsClient}
                        setFilterButtonsClient={this.props.setFilterButtonsClient}
                        setFilterButtonsArrayClient={this.props.setFilterButtonsArrayClient}

                    />
                }

                <div className="scheduleDiv">
                    {console.log("CURRENT START DATE - " + this.props.currentStartDate)}
                    
                    <MyCalendar 
                        eventList={this.props.eventList}
                        getTimeAndDate={this.props.getTimeAndDate}
                        displayDialog={this.props.displayDialog} 
                        setDisplayDialog={this.props.setDisplayDialog}
                        setEventClickedObject={this.props.setEventClickedObject}
                        prettifyTime={this.props.prettifyTime} 
                        user={this.props.user}
                        checkAdminPriviledge={this.props.checkAdminPriviledge}
                        caretakerListNames={this.props.caretakerListNames}
                        filterSelected={this.props.filterSelected}
                        InitialLoadShifts={this.InitialLoadShifts}
                    />
                    
                        
                    {this.props.checkAdminPriviledge() && 
                        <AddShiftBox 
                            addShift={this.props.addShift} 
                            clientList={this.props.clientList} 
                            caretakerList={this.props.caretakerList}
                            caretakerMasterList={this.props.caretakerMasterList}
                            caretakerMasterListNames={this.props.caretakerMasterListNames}
                            renderRepeatOptions={this.props.renderRepeatOptions}
                            setRenderRepeatOptions={this.props.setRenderRepeatOptions}
                            setRenderFlatFeeOptions={this.props.setRenderFlatFeeOptions}
                            renderFlatFeeOptions={this.props.renderFlatFeeOptions}
                            caretakerListNames={this.props.caretakerListNames}
                            renderOvernightOptions={this.props.renderOvernightOptions}
                            setRenderOvernightOptions={this.props.setRenderOvernightOptions}
                        />
                    }

                    {this.props.checkAdminPriviledge() &&
                        <HoursBox 
                            caretakerList={this.props.caretakerList} 
                            currentStartDate={this.props.currentStartDate} 
                            currentEndDate={this.props.currentEndDate} 
                            currentStartTimestr={this.props.currentStartTimestr} 
                            currentEndTimestr={this.props.currentEndTimestr} 
                            eventList={this.props.eventList}
                            caretakerMasterList={this.props.caretakerMasterList}
                            clientList={this.props.clientList}
                        />
                    }

                    {this.props.checkAdminPriviledge() &&
                        <HoursBoxClients
                            clientList={this.props.clientList}
                            currentStartDate={this.props.currentStartDate}
                            currentEndDate={this.props.currentEndDate} 
                            currentStartTimestr={this.props.currentStartTimestr} 
                            currentEndTimestr={this.props.currentEndTimestr} 
                            eventList={this.props.eventList}
                        />
                    }

                    {/* this.props.checkAdminPriviledge() &&
                        <TimeOffRequestUser
                            caretakerListNames={this.props.caretakerListNames}
                            checkAdminPriviledge={this.props.checkAdminPriviledge}
                        />
                    */ }
                    
                    {
                        <AddShiftFormDialog 
                            displayDialog={this.props.displayDialog} 
                            setDisplayDialog={this.props.setDisplayDialog}
                            eventClickedObject={this.props.eventClickedObject}
                            editTimeWorked={this.props.editTimeWorked}
                            checkAdminPriviledge={this.props.checkAdminPriviledge}
                            deleteShift={this.props.deleteShift}
                            deleteFutureShifts={this.props.deleteFutureShifts}
                            caretakerList={this.props.caretakerList}
                            caretakerListNames={this.props.caretakerListNames}
                            eventList={this.props.eventList}
                            editInfoForShift={this.props.editInfoForShift}
                            clientListNames={this.props.clientListNames}
                        />
                    }

                    {
                        <DeleteCaretakerDialog
                            displayDeleteCaretakerDialog={this.props.displayDeleteCaretakerDialog}
                            setDisplayDeleteCaretakerDialog={this.props.setDisplayDeleteCaretakerDialog}
                            firestore={this.props.firestore}
                            InitialLoadCaretakers={this.InitialLoadCaretakers}
                            InitialLoadShifts={this.InitialLoadShifts}
                            setCaretakerToDeleteName={this.props.setCaretakerToDeleteName}
                            caretakerToDeleteName={this.props.caretakerToDeleteName}
                            setCaretakerToDeleteID={this.props.setCaretakerToDeleteID}
                            caretakerToDeleteID={this.props.caretakerToDeleteID}
                            userToDeleteID={this.props.userToDeleteID}
                            masterEventList={this.props.masterEventList}

                        />
                    }

                    {
                        <DeleteClientDialog
                            displayDeleteClientDialog={this.props.displayDeleteClientDialog}
                            setDisplayDeleteClientDialog={this.props.setDisplayDeleteClientDialog}
                            firestore={this.props.firestore}
                            InitialLoadClients={this.InitialLoadClients}
                            setClientToDeleteName={this.props.setClientToDeleteName}
                            clientToDeleteName={this.props.clientToDeleteName}
                            setClientToDeleteID={this.props.setClientToDeleteID}
                            clientToDeleteID={this.props.clientToDeleteID}
                        />
                    }

                    {
                        <MyAppointments
                            aptEventList={this.props.appointmentList}
                            user={this.props.user}
                            prettifyTime={this.props.prettifyTime}
                            getTimeAndDate={this.props.getTimeAndDate}
                            displayEditAppointmentDialog={this.props.displayEditAppointmentDialog}
                            setDisplayEditAppointmentDialog={this.props.setDisplayEditAppointmentDialog}
                            setAptClickedObject={this.props.setAptClickedObject}
                            aptClickedObject={this.props.aptClickedObject}
                        />
                    }

                    {/*
                        <DeactivatedClientsDialog
                            displayDeactivatedClientsDialog={this.props.displayDeactivatedClientsDialog}
                            setDisplayDeactivatedClientsDialog={this.props.setDisplayDeactivatedClientsDialog}
                            deactivatedClientsList={this.props.deactivatedClientsList}
                        />
                */}

                    {
                        <EditAppointmentDialog
                            setDisplayEditAppointmentDialog={this.props.setDisplayEditAppointmentDialog}
                            displayEditAppointmentDialog={this.props.displayEditAppointmentDialog}
                            appointmentList={this.props.appointmentList}
                            aptClickedObject={this.props.aptClickedObject}
                            deleteApt={this.props.deleteApt}
                            setAptToDeleteID={this.props.setAptToDeleteID}
                            setDisplayConfirmDeleteAppointmentDialog={this.props.setDisplayConfirmDeleteAppointmentDialog}
                            editAptNotes={this.props.editAptNotes}
                        />
                    }

                    {
                        <ConfirmDeleteAppointmentDialog
                            setDisplayConfirmDeleteAppointmentDialog={this.props.setDisplayConfirmDeleteAppointmentDialog}
                            displayConfirmDeleteAppointmentDialog={this.props.displayConfirmDeleteAppointmentDialog}
                            deleteApt={this.props.deleteApt}
                        />
                    }

                    {
                        <AddAppointmentBox 
                            addShift={this.props.addShift}
                            clientList={this.props.clientList}
                            addAppointment={this.props.addAppointment}
                            user={this.props.user}
                            userList={this.props.userList}
                            
                        />
                    }
                
                
                
                {this.props.checkAdminPriviledge() && 
                    <AddCaretakerBox 
                        addCaretaker={this.props.addCaretaker}
                        addUser={this.props.addUser}
                    />
                } 

                {this.props.checkAdminPriviledge() && 
                    <EditCaretakersBox 
                        caretakerList={this.props.caretakerList} 
                        firestore={this.props.firestore}
                        InitialLoadCaretakers={this.InitialLoadCaretakers} 
                        InitialLoadShifts={this.InitialLoadShifts}
                        setCaretakerToDeleteID={this.props.setCaretakerToDeleteID}
                        setCaretakerToDeleteName={this.props.setCaretakerToDeleteName}
                        setDisplayDeleteCaretakerDialog={this.props.setDisplayDeleteCaretakerDialog}
                        setUserToDeleteID={this.props.setUserToDeleteID}
                        userList={this.props.userList}
                        caretakerMasterList={this.props.caretakerMasterList}
                        deactivatedCaretakersList={this.props.deactivatedCaretakersList}
                        restoreCaretaker={this.props.restoreCaretaker}
                    />
                }

                {this.props.checkAdminPriviledge() &&
                    <RaiseBox
                        caretakerList={this.props.caretakerList}
                        firestore={this.props.firestore}
                    />
                    

                }       

                {this.props.checkAdminPriviledge() && 
                    <AddClientBox 
                        addClient={this.props.addClient}

                    />
                }
                
                 
                {this.props.checkAdminPriviledge() && 
                    <EditClientsBox 
                        clientList={this.props.clientList}
                        firestore={this.props.firestore}
                        InitialLoadClients={this.InitialLoadClients}
                        setClientToDeleteID={this.props.setClientToDeleteID}
                        setClientToDeleteName={this.props.setClientToDeleteName}
                        setDisplayDeleteClientDialog={this.props.setDisplayDeleteClientDialog}
                        clientMasterList={this.props.clientMasterList}
                        displayDeactivatedClientsDialog={this.props.displayDeactivatedClientsDialog}
                        setDisplayDeactivatedClientsDialog={this.props.setDisplayDeactivatedClientsDialog}
                        deactivatedClientsList={this.props.deactivatedClientsList}
                        restoreClient={this.props.restoreClient}
                    />
                } 
               
               { /*
              
                {!this.props.checkAdminPriviledge() &&
                    // TODO: put mileage input here - client side + admin
                        <MileageInputUser 
                            clientListNames={this.props.clientListNames}
                            checkAdminPriviledge={this.props.checkAdminPriviledge}
                        />
                }
            

                {this.props.checkAdminPriviledge() &&
                        <MileageInputAdmin
                            clientListNames={this.props.clientListNames}
                            checkAdminPriviledge={this.props.checkAdminPriviledge}
                            caretakerListNames={this.props.caretakerListNames}
                        />
                }
            
                {this.props.checkAdminPriviledge() &&
                        <MileageAdmin />
                }
                
            */
            }
                

                

                </div>
            </div>
        )
    }
}

